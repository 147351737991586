import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "./IconButton";
import SelectionModal from "./modals/SelectionModal";
import { AttachmentData, ModelRef, ReservationDetailData, HistoryChannelData, ContactMinimalData } from "../types";
import Attachment from "./Attachment";

export interface HistoryInputProps {
    /**
     * Whether the input is disabled or not. By default it is enabled.
     */
    disabled?: boolean;

    /**
     * Placeholder text shown in the input, in a lighter color.
     */
    placeholder?: string;

    /**
     * Check if right side panel is opened
     */
    isSidePanelOpened?: boolean;

    /**
     * Optional onChange handler.
     */
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

    /**
     * What happens when the send button is clicked.
     * The function must return whether the message was successfully sent or not after trying to send it.
     * If it was successfully sent, the data in the input is cleared. While it is being processed,
     * the input is disabled.
     */
    onSend: (attachments: AttachmentData[]) => Promise<boolean>;

    /**
     * Triggered after the send is finished.
     */
    onEndSend?: () => void;

    /**
     * Triggered when channel is selected
     */
    onSelectChannel: (channel: number, name: string) => void;

    /**
     * Used to get info from the reservation in the input like attachments.
     */
    reservationData: ReservationDetailData;

    /**
     * Communication channels from different providers
     */
    historyChannels: Array<HistoryChannelData>;

    /**
     * State passed fromn parent that has current channel name
     */
    currentConversationName: string;
}

/**
 * The input shown at the bottom of the history tab, in which the user can type a message that will be sent
 * as an email to the final customer related to the linked reservation.
 */
const HistoryInput: React.FC<HistoryInputProps> = ({
    disabled = false,
    isSidePanelOpened,
    placeholder,
    onChange,
    onSend,
    onEndSend,
    onSelectChannel,
    reservationData,
    historyChannels,
    currentConversationName,
}) => {
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const [value, setValue] = useState("");
    const [attachments, setAttachments] = useState<AttachmentData[]>([]);
    const [messageInputHasChanged, setMessageInputHasChanged] = useState<boolean>(false);
    const [isSelectAttachmentModalVisible, setIsSelectAttachmentModalVisible] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [infoName, setInfoName] = React.useState<string>(currentConversationName);
    let chatParterName: string;
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSend = async () => {
        if (messageInputHasChanged) {
            setIsSendingMessage(true);
            await onSend(attachments);
            setIsSendingMessage(false);
            setValue("");
            setAttachments([]);
            onEndSend && onEndSend();
        }
        setMessageInputHasChanged(false);
    };

    const handleSelect = useCallback(
        (channel: number, name: string) => {
            handleClose();
            onSelectChannel(channel, name);
            setInfoName(name);
        },
        [onSelectChannel]
    );

    const setChannelName = (channel: HistoryChannelData) => {
        if (channel.participants[1]) {
            channel.participants.forEach((participant: ContactMinimalData) => {
                if (participant.full_name === historyChannels[0].participants[0].full_name) {
                    return;
                }
                chatParterName = participant.full_name;
            });
        } else {
            chatParterName = "Histórico expediente";
        }

        return chatParterName;
    };

    return (
        <div
            className={classNames(
                "flex flex-row gap-2 fixed z-10 bottom-0 right-0 mt-1.5 text-blue-light-2 focus-within:text-yellow px-8 mb-4 transition-left ease-in-out duration-200",
                `${isSidePanelOpened && !isSidebarOpen && "1 w-[calc(100vw-492px)] left-[20rem] left-0 sm:left-20"}`,
                `${isSidePanelOpened && isSidebarOpen && "2 w-[calc(100vw-712px)] left-[20rem] lg:left-80"}`,
                `${!isSidePanelOpened && isSidebarOpen && "3 sm:w-[calc(100vw-312px)] left-[rem] lg:left-80"}`,
                `${!isSidePanelOpened && !isSidebarOpen && "4 w-full sm:w-[calc(100vw-92px)] left-0 sm:left-20"}`
            )}
        >
            <div className="basis-[80px] border border-blue-light-3 bg-white rounded-md shadow">
                <div className="relative bottom-[6px]">
                    <IconButton
                        icon="forum"
                        title="ver canales"
                        color="grey"
                        onClick={handleClick}
                        extraClass="p-6 relative top-[2px] right-[10px]"
                    />
                </div>
            </div>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        /* maxHeight: ITEM_HEIGHT * 4.5, */
                        width: "auto",
                        minWidth: "20ch",
                    },
                    sx: {
                        boxShadow: 0,
                        border: "1px solid rgb(195, 204, 216)",
                        borderRadius: "5px",
                    },
                }}
            >
                {historyChannels.map((channel: HistoryChannelData, index: number) => {
                    const name = setChannelName(channel);
                    return (
                        <MenuItem key={channel.id} onClick={() => handleSelect(index, name)}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Menu>

            <div className="w-full border-blue-light-3 bg-white appearance-none border rounded-md w-full py-4 pl-4 pr-[80px] shadow relative">
                <p className="absolute right-[17px] bottom-[67px] text-[12px] align-right p-1 text-grey shadow shadow-white bg-white">
                    chat con: <span>{infoName}</span>
                </p>
                <div className={classNames("absolute right-2 top-7", isSendingMessage && "spinner")}>
                    <div className="flex justify-end">
                        {!isSendingMessage && (
                            <IconButton
                                icon="attach_file"
                                title="Adjuntar archivo"
                                color="yellow"
                                onClick={() => setIsSelectAttachmentModalVisible(true)}
                            />
                        )}

                        <IconButton
                            icon={isSendingMessage ? "motion_photos" : "send"}
                            title="Enviar mensaje"
                            color="yellow"
                            onClick={handleSend}
                        />
                    </div>
                </div>
                <textarea
                    rows={2}
                    className={classNames(
                        "border-blue-light-3 w-full no-scrollbar h-full",
                        "placeholder-grey-light-1 placeholder-opacity-50 leading-tight resize-none font-sans",
                        "focus:outline-none focus:bg-white focus:border-yellow focus:shadow-outline",
                        disabled ? "text-grey cursor-default" : "text-blue-dark"
                    )}
                    placeholder={placeholder}
                    disabled={disabled || isSendingMessage}
                    onChange={(e) => {
                        setMessageInputHasChanged(true);
                        setValue(e.target.value);
                        onChange && onChange(e);
                    }}
                    value={value}
                />
            </div>

            {attachments.length > 0 && (
                <div className="flex flex-wrap text-black">
                    {attachments.map((attachment) => (
                        <div key={attachment.id} className="p-2">
                            <Attachment
                                name={attachment.name}
                                onDelete={() => setAttachments(attachments.filter((at) => at.id !== attachment.id))}
                            />
                        </div>
                    ))}
                </div>
            )}

            {/* Modals */}
            <div className="text-black">
                <SelectionModal
                    title="Documentación de la reserva"
                    description="Selecciona qué documentos adjuntar de la lista de documentación asociada a esta resera."
                    header="Fichero"
                    searchBoxPlaceholder="Buscar nombre del fichero"
                    visible={isSelectAttachmentModalVisible}
                    visibleHandler={setIsSelectAttachmentModalVisible}
                    values={reservationData.attachments.map((attachment) => ({
                        id: attachment.id,
                        name: attachment.name,
                    }))}
                    multiselection
                    onAccept={(selected: ModelRef[]) => {
                        const selectedIds = selected.map((at) => at.id).filter((id) => id >= 0);
                        if (!selectedIds) return;
                        const attachmentIds = attachments.map((at) => at.id);
                        const newAttachments = reservationData.attachments.filter(
                            (attachment) =>
                                selectedIds.includes(attachment.id) && !attachmentIds.includes(attachment.id)
                        );
                        setAttachments(newAttachments);
                    }}
                />
            </div>
        </div>
    );
};

export default HistoryInput;
