/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TextInput from "../TextInput";
import { PdfTemplate, Choice } from "../../types";
import Button from "../Button";
import Modal from "../Modal";
import ColorPickerModal from "./ColorPickerModal";
import Selection from "../Selection";
import useModelRef from "../../useModelRef";

export interface CustomTemplateModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    onAccept: (newCustomTemplate: PdfTemplate) => void;

    primaryColor?: object;

    pdfTemplates: PdfTemplate[];
}

interface ColorPalette {
    primary: string;
    secondary: string;
}

/**
 * A simple modal whose only purpose is to show a list of values from a m2o field,
 * for the user to choose one or more from, with a search box to filter by name.
 *
 * A list with a single column is shown, which should be the value of the ModelRef.
 */
const CustomTemplateModal: React.FC<CustomTemplateModalProps> = ({
    visible,
    pdfTemplates,
    visibleHandler,
    onAccept,
}) => {
    const { getChoiceRef } = useModelRef();
    const typos = getChoiceRef("pdfFonts");
    const [selectedTypography, setSelectedTypgraphy] = useState<Choice>({ value: "Oswald", display: "" });
    const [selectedTemplateStyle, setSelectedTemplateStyle] = useState<Choice>({
        value: "mokenapp/template_sivas",
        display: "Sivas",
    });
    const [customTemplateName, setCustomTemplateName] = useState<string>("");
    const [paletteColorType, setPaletteColorType] = useState<string>("primary");
    const [colorPalette, setColorPalette] = useState<ColorPalette>({ primary: "000000FF", secondary: "000000FF" });
    const [isColorPickerModalVisible, setIsColorPickerModalVisible] = useState(false);
    const thumbnailChoices: Choice[] = [];
    const typographyOptions: Choice[] = [];

    const RGBAToHexA = (r: string, g: string, b: string, a: string) => {
        const aIn255 = Math.round(Number(a) * 255);
        let newr = parseInt(r, 10).toString(16);
        let newg = parseInt(g, 10).toString(16);
        let newb = parseInt(b, 10).toString(16);
        let newa = parseInt(String(aIn255), 10).toString(16);

        if (r.length === 1) newr = `0${r}`;
        if (g.length === 1) newg = `0${g}`;
        if (b.length === 1) newb = `0${b}`;
        if (String(aIn255).length === 1) newa = `0${a}`;

        return `${newr}${newg}${newb}${newa}`;
    };

    pdfTemplates &&
        pdfTemplates.map(
            (template) =>
                template.is_moken_template &&
                thumbnailChoices.push({
                    value: template.template,
                    display: template.name,
                    thumbnail: template.thumbnail,
                })
        );

    typos &&
        typos.map((typo) => {
            const imageLocation = `./resources/fonts/${typo.display}.svg`;
            return typographyOptions.push({ value: typo.value, display: "", thumbnail: imageLocation.toLowerCase() });
        });

    return (
        <Modal visible={visible} visibleHandler={visibleHandler}>
            <div className="flex flex-col w-full max-h-[calc(100vh-80px)] p-12 overflow-y-auto">
                <h2 className="text-3xl font-bold">Crea tu propia plantilla de itinerario</h2>
                <p className="mt-12 mb-4 text-xl font-bold">1. Nombre</p>
                <TextInput
                    id="modal"
                    value={customTemplateName && customTemplateName}
                    onChange={(e) => setCustomTemplateName(e.target.value)}
                    label="Nombre"
                />
                <p className="mt-12 mb-4 text-xl font-bold">2. Diseño</p>
                <Selection
                    id="pdf_template"
                    size="small"
                    label="Selecciona diseño"
                    choices={thumbnailChoices}
                    defaultValue={selectedTemplateStyle}
                    onChange={(choice) => {
                        setSelectedTemplateStyle(choice);
                    }}
                />

                <p className="mt-14 mb-4 text-xl font-bold">3. Tipografía</p>

                <Selection
                    id="typography"
                    size="small"
                    label="Selecciona tipografía"
                    choices={typographyOptions}
                    defaultValue={selectedTypography}
                    onChange={(choice) => {
                        setSelectedTypgraphy(choice);
                    }}
                />
                <div>
                    <p className="mt-14 mb-4 text-xl font-bold">4. Paleta de color</p>
                </div>
                <div className="w-full mt-4">
                    <div>
                        <h4 className="text-grey-dark-2 text-lg border-b border-grey-dark-2 pb-1">Color primario</h4>
                        <div className="flex w-full items center mt-6 justify-between">
                            <div
                                className="w-[80px] h-[40px] pl-8 col-span-2 min-w-[80px] bg-red rounded-md"
                                style={{ background: `#${colorPalette.primary}` }}
                            />
                            <div className="ml-2">
                                <Button
                                    type="btn_transparent"
                                    label="seleccionar color"
                                    onClick={() => {
                                        setIsColorPickerModalVisible(true);
                                        setPaletteColorType("primary");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <h4 className="text-grey-dark-2 text-lg border-b border-grey-dark-2 pb-1">Color secundario</h4>
                        <div className="flex w-full items center mt-6 justify-between">
                            <div
                                className="w-[80px] h-[40px] pl-8 col-span-2 min-w-[80px] rounded-md"
                                style={{ background: `#${colorPalette.secondary}` }}
                            />
                            <div className="ml-2">
                                <Button
                                    type="btn_transparent"
                                    label="seleccionar color"
                                    onClick={() => {
                                        setIsColorPickerModalVisible(true);
                                        setPaletteColorType("secondary");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row w-full items-center justify-end mt-16">
                    <div className="pr-2">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeModal"))}
                            title="Cancelar invitación"
                        />
                    </div>
                    <div>
                        <Button
                            label="Guardar"
                            type="btn_dark"
                            title="Guardar plantilla"
                            onClick={() =>
                                onAccept({
                                    id: pdfTemplates.length + 1,
                                    name: customTemplateName || "Sin nombre",
                                    thumbnail: "./resources/templates/blank_custom_template.png",
                                    primary_color: colorPalette.primary,
                                    accent_color: colorPalette.secondary,
                                    font: selectedTypography.value,
                                    is_moken_template: false,
                                    template: selectedTemplateStyle.value,
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <ColorPickerModal
                visible={isColorPickerModalVisible}
                visibleHandler={setIsColorPickerModalVisible}
                colorType={paletteColorType}
                onSave={(colorType, color) => {
                    if (!color) return;
                    setColorPalette({
                        ...colorPalette,
                        [colorType]: RGBAToHexA(`${color.r}`, `${color.g}`, `${color.b}`, `${color.a}`),
                    });
                    setIsColorPickerModalVisible(false);
                }}
            />
        </Modal>
    );
};

export default CustomTemplateModal;
