import React, { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import IconButton from "../components/IconButton";
import Checkbox from "../components/Checkbox";
import Container from "../components/Container";
import ImageSelector from "../components/ImageSelector";
import Many2one from "../components/Many2one";
import TextInput from "../components/TextInput";
import { DayBlockData, ModelRef, TravelDayData, MokenImage, Location, ReservationDetailData } from "../types";
import { getInitials, INVALID_MODELREF } from "../utils";
import Itinerary, { DayIdentifier } from "../components/Itinerary";
import axiosApi from "../axiosApi";
import useAlert from "../useAlert";
import useModelRef from "../useModelRef";
import LoadingModal from "../components/modals/LoadingModal";

interface ItineraryTabProps {
    // Itinerary is held by the parent
    reservationData: ReservationDetailData | null;
    itinerary: DayBlockData[];
    setItinerary: (newItinerary: DayBlockData[]) => void;

    reservationId: number;
    type: "reservations" | "templates";
    selectedVersion?: number;
    isSidePanelOpened?: boolean;
}

const ItineraryTab: React.FC<ItineraryTabProps> = ({
    reservationData,
    itinerary,
    isSidePanelOpened,
    setItinerary,
    reservationId,
    type,
    selectedVersion,
}) => {
    const [selectedBlock, setSelectedBlock] = useState<number>(0);
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [fieldErrors, setFieldErrors] = useState(new Map<string, string>());
    const [isCreatingNewDay, setIsCreatingNewDay] = useState(false);
    const [countries, setCountries] = useState<Array<ModelRef>>([]);
    const [locations, setLocations] = useState<Array<Location>>([]);
    const [availableImages, setAvailableImages] = useState<Array<MokenImage>>([]);
    const [dayPlace, setDayPlace] = useState<number>(0);

    const { addAlert } = useAlert();
    const { getModelRef } = useModelRef();

    const selectedItinerary = selectedVersion
        ? itinerary.filter((block) => block.version === selectedVersion)
        : itinerary;

    const itineraryStartDate = reservationData?.travel_start_date;
    const travelDay = selectedItinerary[selectedBlock]?.days[selectedDay];
    const providerImage = travelDay?.provider_image;
    if (providerImage) {
        providerImage.author = getInitials(selectedItinerary[selectedBlock].provider?.full_name || "");
        providerImage.author_image = selectedItinerary[selectedBlock].provider?.profile_image;
        providerImage.is_provider = true;
    }

    const locationFilteredM2o = locations
        .filter((l) => {
            if (l.country === null) {
                // Special locations (with no country) are always shown
                return true;
            }
            if (
                selectedBlock < selectedItinerary.length &&
                travelDay &&
                travelDay.country &&
                travelDay.country.id !== 0
            ) {
                // If there is a valid selected country, filter out locations from otuer countries
                return l.country === travelDay.country.id;
            }
            return false;
        })
        .sort((a, b) => {
            if (!a.country && b.country) {
                return -1;
            }
            if (a.country && !b.country) {
                return 1;
            }
            return 0;
        })
        .map((l) => ({ id: l.id, name: l.name } as ModelRef));

    const getItineraryDays = (): DayIdentifier[] => {
        const days: DayIdentifier[] = [];
        for (let i = 0; i < selectedItinerary.length; i += 1) {
            for (let j = 0; j < selectedItinerary[i].days.length; j += 1) {
                const day = selectedItinerary[i].days[j];
                days.push({
                    id: day.id,
                    block: selectedItinerary[i].position,
                    block_id: selectedItinerary[i].id,
                    position: day.position,
                    title: day.title,
                    country: day.country,
                    location: day.location,
                });
            }
        }
        return days;
    };

    const handleErrorResponse = useCallback(
        (err: Error | AxiosError, isItinerary = false) => {
            if (axios.isAxiosError(err) && err.response) {
                const newFieldsWithError = new Map<string, string>();
                const { data } = err.response;
                const wrongFields = Object.entries(data);
                switch (err.response.status) {
                    case 400:
                        addAlert("Algunos campos requeridos no han sido correctamente rellenados.", "error");
                        for (let i = 0; i < wrongFields.length; i += 1) {
                            const [field, message] = wrongFields[i];
                            if (Array.isArray(message) && typeof message[0] === "string") {
                                newFieldsWithError.set(`${isItinerary ? "itinerary_" : ""}${field}`, message[0]);
                            }
                        }
                        setFieldErrors(newFieldsWithError);
                        break;
                    default:
                        addAlert(`Ha ocurrido un error inesperado. Código de error: ${err.response.status}`, "error");
                        break;
                }
            }
        },
        [setFieldErrors, addAlert]
    );

    /**
     * Saves the currently selected itinerary day to the server.
     * If the itinerary day is a new day, it is properly created in the database, otherwise the existing
     * day is modified.
     */
    const saveCurrentItineraryDay = () => {
        const dataChanged = setTimeout(() => {
            if (!travelDay)
                return new Promise((resolve) => {
                    resolve(false);
                });

            const dayData = { ...travelDay };
            if (travelDay.image) {
                dayData.image = {
                    id: travelDay.image.id,
                    image: "",
                    is_moken_image: travelDay.image.is_moken_image,
                };
            }

            return axiosApi.put(`/${type}/${reservationId}/save_day/`, dayData).then(() => {
                setFieldErrors(new Map());

                return true;
            });
        }, 1000);

        return () => clearTimeout(dataChanged);
    };

    const onAddItineraryDay = async (block: number) => {
        const successSaving = selectedItinerary.length === 0 || (await saveCurrentItineraryDay());
        if (!successSaving && travelDay) return;

        setIsCreatingNewDay(true);
        // The day added through the add day button will be added in its own block.
        // Location, country and hotels are carried over from the previous day.
        const newDay = {
            // use some random number so that the details update, when creating the actual day an id will automatically be set
            id: Math.ceil(Math.random() * 100000 + 99),
            position: 0,
            breakfast: false,
            lunch: false,
            dinner: false,
            all_included: false,
            title: "",
            description: "",
            country: travelDay?.country,
            location: travelDay?.location,
            accommodation_location: travelDay?.accommodation_location,
            standard_accommodation: travelDay?.standard_accommodation,
            superior_accommodation: travelDay?.superior_accommodation,
            luxury_accommodation: travelDay?.luxury_accommodation,
            standard_accommodation_link: travelDay?.standard_accommodation_link,
            superior_accommodation_link: travelDay?.superior_accommodation_link,
            luxury_accommodation_link: travelDay?.luxury_accommodation_link,
        } as TravelDayData;
        const newBlock = selectedItinerary.length > 0 ? block + 1 : 0;
        await axiosApi
            .post(`/${type}/${reservationId}/add_day/`, {
                day: newDay,
                block: newBlock,
            })
            .then((response) => {
                const reservation = response.data;
                // Update the state with the new itinerary info
                setItinerary(reservation.itinerary);
                setFieldErrors(new Map());
                // FIXME: Find the new day/block instead of computing it
                setSelectedBlock(newBlock);
                setSelectedDay(0);
                setDayPlace(dayPlace + 1);
            })
            .catch((err) => {
                handleErrorResponse(err, true);
            })
            .finally(() => setIsCreatingNewDay(false));
    };

    const onDuplicateItineraryDay = async () => {
        // First save current changes
        const successSaving = selectedItinerary.length === 0 || (await saveCurrentItineraryDay());
        if (!successSaving) return;

        // Perform day move in the database
        axiosApi
            .post(`/${type}/${reservationId}/duplicate_day/`, {
                day: travelDay.id,
            })
            .then((response) => {
                setItinerary(response.data);
                setSelectedDay(travelDay.position + 1);
                setDayPlace(dayPlace + 1);
            })
            .catch(() => addAlert("No se ha podido duplicar el día del itinerario.", "error"));
    };

    const onDeleteItineraryDay = (dayId: number) => {
        if (!isCreatingNewDay) {
            // Delete it from the server as well
            axiosApi
                .post(`/${type}/${reservationId}/delete_day/`, { id: dayId })
                .then((response) => {
                    setItinerary(response.data);
                    // Select the day before the deleted one
                    if (selectedDay === 0) {
                        setSelectedBlock(selectedBlock > 0 ? selectedBlock - 1 : 0);
                    } else {
                        setSelectedDay(selectedDay - 1);
                    }
                    addAlert("El día se ha eliminado correctamente.", "success");
                })
                .catch(() => addAlert("No se ha podido eliminar el día del itinerario.", "error"));
        } else {
            setIsCreatingNewDay(false);
            // Select the last day of the previous block
            const newSelectedBlock = selectedBlock > 0 ? selectedBlock - 1 : 0;
            const previousBlock = selectedItinerary[newSelectedBlock];
            if (previousBlock) {
                setSelectedDay(previousBlock.days.length - 1);
            }
            setItinerary(itinerary.filter((block) => block.id !== selectedItinerary[selectedBlock].id));
            setSelectedBlock(newSelectedBlock);
        }
    };

    const onSelectItineraryDay = async (dayPosition: number, block: number, positionInItinerary: number) => {
        setDayPlace(positionInItinerary);
        if (dayPosition === selectedDay && block === selectedBlock) return;
        // TODO: Instead of saving automatically, show popup to save/discard changes to current day
        setSelectedBlock(block);
        setSelectedDay(dayPosition);
        const successSaving = await saveCurrentItineraryDay();
        if (successSaving) {
            setSelectedBlock(block);
            setSelectedDay(dayPosition);
        }
    };

    const handleItinerarySort = (sorting: DayIdentifier[]) => {
        // Retrieve the new day sorting and save it on the server if it changed
        const days = getItineraryDays();
        if (sorting.length !== days.length) return;
        let sortingChanged = false;
        for (let i = 0; i < sorting.length && !sortingChanged; i += 1) {
            if (sorting[i].id !== days[i].id) {
                sortingChanged = true;
            }
        }

        if (sortingChanged) {
            // Need to update the new sorting to the server
            axiosApi
                .post(`/${type}/${reservationId}/sort_itinerary/`, {
                    sorting,
                    type: "day",
                })
                .then((response) => {
                    setItinerary(response.data);
                })
                .catch(() => addAlert("No se ha podido mover el día del itinerario.", "error"));
        }
    };

    const getDateForItineraryDay = (dateString: string, position: number) => {
        const newdate = new Date(dateString);
        newdate.setDate(newdate.getDate() + position);
        const date = `0${newdate.getDate()}`;
        const month = `0${newdate.getMonth() + 1}`;
        const year = newdate.getFullYear();
        return `${date.slice(-2)}/${month.slice(-2)}/${year}`;
    };

    const handleSelectedDayChange = <K extends keyof TravelDayData, P extends TravelDayData[K]>(
        field: K,
        ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
    ) => {
        const modifiedItinerary = itinerary.slice();

        modifiedItinerary[modifiedItinerary.indexOf(selectedItinerary[selectedBlock])].days[selectedDay][field] = ev
            .currentTarget.value as P;
        saveCurrentItineraryDay();
        setItinerary(modifiedItinerary);
    };

    const handleM2oSelectedDayChange = <K extends keyof TravelDayData, P extends TravelDayData[K]>(
        field: K,
        selected: ModelRef
    ) => {
        const modifiedItinerary = itinerary.slice();
        travelDay.image = locations[selected.id].default_image;
        modifiedItinerary[modifiedItinerary.indexOf(selectedItinerary[selectedBlock])].days[selectedDay][field] =
            selected as P;
        setItinerary(modifiedItinerary);
    };

    const fetchLocations = useCallback(() => {
        axiosApi
            .get("/locations/")
            .then((response) => setLocations(response.data))
            .catch(() => {
                addAlert("Ha habido un error al recuperar los lugares.", "error");
            });
    }, [addAlert]);

    // Fetch countries only once, only if we've opened a reservation detail
    useEffect(() => {
        if (countries.length === 0) {
            getModelRef("countries").then(
                (retrievedCountries) => retrievedCountries && setCountries(retrievedCountries)
            );
        }
        if (locations.length === 0) {
            fetchLocations();
        }
    }, [countries, locations, getModelRef, fetchLocations]);

    // When the selected day changes and it has a valid country and location, fetch the available images for that day
    useEffect(() => {
        if (!travelDay) return;

        // If no valid location is selected, do nothing
        if (!travelDay.location || travelDay.location.id === 0) {
            return;
        }

        // FIXME: Don't call this every time itinerary data changes, only if country or location changes
        axiosApi
            .get(`/locations/${travelDay.location.id}/available_images/`)
            .then((response) => {
                const newAvailableImages = response.data as MokenImage[];

                if (travelDay.image && travelDay.image.is_moken_image) {
                    let imageIdx = -1;
                    if (travelDay.image) {
                        imageIdx = newAvailableImages.findIndex(
                            (availableImage) => availableImage.id === travelDay.image?.id
                        );
                    }

                    if (imageIdx >= 0) {
                        travelDay.image = newAvailableImages[imageIdx];
                    } else {
                        // The selected image is not in the available image list, which can happen if we change location/country,
                        // or if we hadn't selected any image yet (new day without location data).
                        // In this case select the first of the new available images.
                        const modifiedItinerary = itinerary.slice();
                        modifiedItinerary[modifiedItinerary.indexOf(selectedItinerary[selectedBlock])].days[
                            selectedDay
                        ].image = newAvailableImages[0];
                        setItinerary(modifiedItinerary);
                    }
                }

                setAvailableImages(newAvailableImages);
            })
            .catch(() => {
                addAlert("Ha habido un error al recuperar las imágenes disponibles para un destino.", "error");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addAlert, travelDay?.location]);

    useEffect(() => {
        if (selectedItinerary.length > 0) {
            setSelectedBlock(0);
            setSelectedDay(0);
        }
    }, [selectedItinerary.length, selectedVersion]);

    return (
        <div className="space-y-5">
            <div className=" mt-6 p-y-6 h-[280px]">
                <div className="w-full h-full bg-white border-y border-y-grey-light-3">
                    <div
                        className={`flex h-full items-center overflow-x-auto overflow-y-hidden px-2 ${
                            isSidePanelOpened ? " w-[calc(100vw-790px)]" : ""
                        }`}
                    >
                        <Itinerary
                            startDate={itineraryStartDate}
                            days={getItineraryDays()}
                            selectedBlock={selectedBlock}
                            selectedDay={selectedDay}
                            onAdd={() => onAddItineraryDay(selectedBlock)}
                            onDelete={onDeleteItineraryDay}
                            onSort={handleItinerarySort}
                            onSelect={onSelectItineraryDay}
                        />
                    </div>
                </div>
            </div>
            {selectedItinerary.length > 0 && travelDay && (
                <Container padding={["r", "l", "b"]}>
                    <div className="flex flex-col pt-10" key={travelDay.id}>
                        <div className="flex justify-end sm:justify-between flex-wrap">
                            <div className="hidden sm:block">
                                <h2
                                    className={`text-2xl font-bold font-sans ${
                                        !travelDay.title && "text-grey-light-2"
                                    }`}
                                >
                                    {travelDay.title ? travelDay.title : "Título sin asignar"}
                                </h2>
                                {itineraryStartDate && (
                                    <p className="mt-2 mb-12 pl-1 text-grey-light-1 text-sm">
                                        {`Fecha de viaje: 
                                        ${getDateForItineraryDay(itineraryStartDate, dayPlace)}`}
                                    </p>
                                )}
                            </div>

                            <div className="flex gap-6 items-start mb-8 sm:mb-0">
                                <div className="relative bottom-[3px]">
                                    <IconButton
                                        key="delete"
                                        title="Eliminar día"
                                        extraClass="text-red"
                                        icon="delete"
                                        iconSize="28px"
                                        onClick={() => {
                                            onDeleteItineraryDay(travelDay.id);
                                        }}
                                    />
                                </div>

                                <IconButton
                                    key="duplicate"
                                    title="Duplicar día"
                                    icon="content_copy"
                                    onClick={() => {
                                        onDuplicateItineraryDay();
                                    }}
                                />
                            </div>
                        </div>
                        <h3 className="text-xl font-bold font-sans mb-10">1. Información general</h3>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                            <div className="col-span-full  xl:col-span-6">
                                <TextInput
                                    id="itinerary_title"
                                    type="text"
                                    label="Título"
                                    defaultValue={travelDay.title}
                                    onChange={(ev) => handleSelectedDayChange("title", ev)}
                                    errorMessage={fieldErrors.get("itinerary_title")}
                                />
                            </div>
                            <div className="mt-4 sm:mt-0 col-span-12 sm:col-span-6 xl:col-span-3">
                                <Many2one
                                    id="itinerary_country"
                                    label="País"
                                    onClick={(selected: ModelRef) => {
                                        // If the country changed, invalidate the location from the old country
                                        if (selected !== travelDay.country) {
                                            handleM2oSelectedDayChange("country", selected);
                                            handleM2oSelectedDayChange("location", INVALID_MODELREF);
                                            saveCurrentItineraryDay();
                                        }
                                    }}
                                    onBlur={(selected: ModelRef) => {
                                        if (selected !== travelDay.country) {
                                            handleM2oSelectedDayChange("country", selected);
                                            handleM2oSelectedDayChange("location", INVALID_MODELREF);
                                            saveCurrentItineraryDay();
                                        }
                                    }}
                                    defaultValue={travelDay.country}
                                    data={countries}
                                    errorMessage={fieldErrors.get("itinerary_country")}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 xl:col-span-3">
                                <Many2one
                                    id="itinerary_location"
                                    label="Lugar"
                                    onBlur={(selected: ModelRef) => handleM2oSelectedDayChange("location", selected)}
                                    defaultValue={travelDay.location}
                                    onClick={(selected: ModelRef) => handleM2oSelectedDayChange("location", selected)}
                                    data={locationFilteredM2o}
                                    errorMessage={fieldErrors.get("itinerary_location")}
                                    onChange={saveCurrentItineraryDay}
                                />
                            </div>
                            <div className="col-span-12 pt-6">
                                <div className="flex flex-col space-y-3">
                                    <span className="font-semibold text-grey text-xs">Dietas incluidas</span>
                                    <div className="flex flex-col sm:flex-row flex-wrap justify-between md:justify-start md:space-x-10">
                                        <Checkbox
                                            label="Desayuno"
                                            checked={travelDay.breakfast}
                                            disabled={travelDay.all_included}
                                            onChange={(checked) => {
                                                const modifiedItinerary = itinerary.slice();
                                                modifiedItinerary[
                                                    modifiedItinerary.indexOf(selectedItinerary[selectedBlock])
                                                ].days[selectedDay].breakfast = checked;
                                                setItinerary(modifiedItinerary);
                                                saveCurrentItineraryDay();
                                            }}
                                        />
                                        <Checkbox
                                            label="Almuerzo"
                                            checked={travelDay.lunch}
                                            disabled={travelDay.all_included}
                                            onChange={(checked) => {
                                                const modifiedItinerary = itinerary.slice();
                                                modifiedItinerary[
                                                    modifiedItinerary.indexOf(selectedItinerary[selectedBlock])
                                                ].days[selectedDay].lunch = checked;
                                                setItinerary(modifiedItinerary);
                                                saveCurrentItineraryDay();
                                            }}
                                        />
                                        <Checkbox
                                            label="Cena"
                                            checked={travelDay.dinner}
                                            disabled={travelDay.all_included}
                                            onChange={(checked) => {
                                                const modifiedItinerary = itinerary.slice();
                                                modifiedItinerary[
                                                    modifiedItinerary.indexOf(selectedItinerary[selectedBlock])
                                                ].days[selectedDay].dinner = checked;
                                                setItinerary(modifiedItinerary);
                                                saveCurrentItineraryDay();
                                            }}
                                        />
                                        <Checkbox
                                            label="Todo incluido"
                                            checked={travelDay.all_included}
                                            onChange={(checked) => {
                                                const modifiedItinerary = itinerary.slice();
                                                const selectedBlockIdx = modifiedItinerary.indexOf(
                                                    selectedItinerary[selectedBlock]
                                                );
                                                modifiedItinerary[selectedBlockIdx].days[selectedDay].all_included =
                                                    checked;
                                                if (checked) {
                                                    // Uncheck the others
                                                    modifiedItinerary[selectedBlockIdx].days[selectedDay].breakfast =
                                                        false;
                                                    modifiedItinerary[selectedBlockIdx].days[selectedDay].lunch = false;
                                                    modifiedItinerary[selectedBlockIdx].days[selectedDay].dinner =
                                                        false;
                                                }
                                                setItinerary(modifiedItinerary);
                                                saveCurrentItineraryDay();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 className="text-xl font-bold font-sans pt-10">2. Detalles del día</h3>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-6 pt-6">
                            <div className="col-span-12 lg:col-span-6">
                                <TextInput
                                    id="itinerary_description"
                                    type="text"
                                    label="Descripción"
                                    defaultValue={travelDay.description}
                                    onChange={(ev) => handleSelectedDayChange("description", ev)}
                                    lines={6}
                                    maxLength={1300}
                                    showMax
                                    errorMessage={fieldErrors.get("itinerary_description")}
                                />
                            </div>
                            <div className="col-span-full lg:col-span-3">
                                <TextInput
                                    id="itinerary_important_notes"
                                    type="text"
                                    label="Notas importantes"
                                    placeholder="Notas importantes del día aquí"
                                    defaultValue={travelDay.important_notes}
                                    onChange={(ev) => handleSelectedDayChange("important_notes", ev)}
                                    errorMessage={fieldErrors.get("itinerary_important_notes")}
                                    maxLength={300}
                                    showMax
                                    lines={6}
                                />
                            </div>
                            <div className="pt-8 xl:pt-0 col-span-12 lg:col-span-3 min-h-[170px]">
                                <ImageSelector
                                    label="Imagen del día"
                                    image={travelDay.image}
                                    onAccept={(image: MokenImage) => {
                                        const modifiedItinerary = itinerary.slice();
                                        modifiedItinerary[
                                            modifiedItinerary.indexOf(selectedItinerary[selectedBlock])
                                        ].days[selectedDay].image = image;
                                        saveCurrentItineraryDay();
                                        setItinerary(modifiedItinerary);
                                    }}
                                    // FIXME: Set available image depending on whether it has been used before or not
                                    mokenImage={
                                        availableImages.length > 0
                                            ? availableImages[0]
                                            : ({ id: 0, image: "" } as MokenImage)
                                    }
                                    providerImage={providerImage}
                                    description={
                                        travelDay.image?.is_unsplash
                                            ? `${travelDay.image.author} (Unsplash)`
                                            : undefined
                                    }
                                    linkTo={travelDay.image?.is_unsplash ? travelDay.image.author_url : undefined}
                                />
                            </div>
                            <div className="mt-4 sm:mt-0 col-span-full">
                                <TextInput
                                    id="itinerary_accommodation_location"
                                    type="text"
                                    label="Noche en"
                                    onChange={(ev) => handleSelectedDayChange("accommodation_location", ev)}
                                    value={travelDay.accommodation_location}
                                    errorMessage={fieldErrors.get("itinerary_accommodation_location")}
                                    predictions={new Set(locations.map((l) => l.name))}
                                />
                            </div>
                            <div className="mt-4 sm:mt-0 col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_standard"
                                    type="text"
                                    label="Alojamiento Standard"
                                    defaultValue={travelDay.standard_accommodation}
                                    onChange={(ev) => handleSelectedDayChange("standard_accommodation", ev)}
                                    errorMessage={fieldErrors.get("itinerary_standard_accommodation")}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_superior"
                                    type="text"
                                    label="Alojamiento Superior"
                                    defaultValue={travelDay.superior_accommodation}
                                    onChange={(ev) => handleSelectedDayChange("superior_accommodation", ev)}
                                    errorMessage={fieldErrors.get("itinerary_superior_accommodation")}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_luxury"
                                    type="text"
                                    label="Alojamiento Luxury"
                                    defaultValue={travelDay.luxury_accommodation}
                                    onChange={(ev) => handleSelectedDayChange("luxury_accommodation", ev)}
                                    errorMessage={fieldErrors.get("itinerary_luxury_accommodation")}
                                />
                            </div>
                            <div className="mt-4 sm:mt-0 col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_standard_link"
                                    type="text"
                                    label="Standard Link"
                                    defaultValue={travelDay.standard_accommodation_link}
                                    onChange={(ev) => handleSelectedDayChange("standard_accommodation_link", ev)}
                                    errorMessage={fieldErrors.get("itinerary_standard_accommodation_link")}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_superior_link"
                                    type="text"
                                    label="Superior Link"
                                    defaultValue={travelDay.superior_accommodation_link}
                                    onChange={(ev) => handleSelectedDayChange("superior_accommodation_link", ev)}
                                    errorMessage={fieldErrors.get("itinerary_superior_accommodation_link")}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <TextInput
                                    id="itinerary_accommodation_luxury_link"
                                    type="text"
                                    label="Luxury Link"
                                    defaultValue={travelDay.luxury_accommodation_link}
                                    onChange={(ev) => handleSelectedDayChange("luxury_accommodation_link", ev)}
                                    errorMessage={fieldErrors.get("itinerary_luxury_accommodation_link")}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Modals */}
                    <LoadingModal visible={isCreatingNewDay} text="Se está creando el nuevo día..." />
                </Container>
            )}
        </div>
    );
};

export default ItineraryTab;
