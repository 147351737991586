/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ModelRef } from "../../types";
import useModelRef from "../../useModelRef";
import SelectionModal from "./SelectionModal";

export interface CountrySelectModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Whether multiple entries can be selected or not.
     */
    multiselection?: boolean;

    /**
     * Currently selected client
     */
    selected?: number;

    /**
     * Callback for setting the reservation's client
     */
    onAccept: (selected: ModelRef[]) => void;
}

const CountrySelectModal: React.FC<CountrySelectModalProps> = ({
    visible,
    visibleHandler,
    selected,
    onAccept,
    multiselection = false,
}) => {
    const [countries, setCountries] = useState<ModelRef[]>([]);

    const { getModelRef } = useModelRef();

    // Fetch countries and interests only once, only if we've accessed the client's detail view
    useEffect(() => {
        if (countries.length === 0) {
            getModelRef("countries").then((retrievedCountries) => {
                if (retrievedCountries) {
                    setCountries(retrievedCountries);
                }
            });
        }
    });

    // TODO: Loading modal?
    if (!countries) return null;

    return (
        <SelectionModal
            visible={visible}
            visibleHandler={visibleHandler}
            selected={selected}
            onAccept={onAccept}
            multiselection={multiselection}
            title="Selecciona un país"
            searchBoxPlaceholder="Buscar nombre del país"
            header="País"
            values={countries}
        />
    );
};

export default CountrySelectModal;
