/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import Flatpickr from "react-flatpickr";
import TextInput from "../components/TextInput";
import ScreenTemplate from "../components/ScreenTemplate";
import axiosApi from "../axiosApi";
import { ContactData, ModelRef, DashboardTravelData, ContactFullData } from "../types";
import Spinner from "../components/Spinner";
import useAlert from "../useAlert";
import map from "../maps/worldmap.json";
import Avatar from "../components/Avatar";
import { getInitials, getCurrentDate } from "../utils";
import IconButton from "../components/IconButton";
import { useAuth } from "../auth";

export interface ReservationRequestData {
    id: number;
    reference: string;
    destinations: Array<ModelRef>;
    comments: string;
    travel_start_date: Date;
    travel_days: number;
    amount_travelers: number;
    travel_type: string;
    budget: number;
    // Contact info
    name: string;
    surname: string;
    email: string;
    phone: string;
    interests: Array<ModelRef>;
    traveler_type: string;
    contact?: ContactData;
}

const DashboardScreen: React.FC = () => {
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);
    const [reservationData, setReservationData] = useState<Array<ReservationRequestData> | null>(null);
    const [todayTravels, setTodayTravels] = useState<[] | null>(null);
    const [currentDateToShow, setCurrentDateToShow] = useState<string>(getCurrentDate(null, null, "slashed"));
    const [nextTravels, setNextTravels] = useState<[] | null>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const { addAlert } = useAlert();
    const opts = {
        wrap: true,
        allowInput: true,
        enableTime: false,
        noCalendar: false,
        time_24hr: true,
        inline: true,
        defaultDate: getCurrentDate(null, null, null),
    };
    const auth = useAuth();
    const fetchCompany = useCallback(() => {
        if (auth.contact === null) return;
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            setCompanyData(data);
        });
    }, [auth.contact]);

    /**
     * Fetch next day data recursively
     */
    const fetchNextDayData = useCallback(
        (dateString: string, arrayOfTravels, numberOfDays) => {
            const newdate = new Date(dateString);
            newdate.setDate(newdate.getDate() + numberOfDays);
            const date = `0${newdate.getDate()}`;
            const month = `0${newdate.getMonth() + 1}`;
            const year = newdate.getFullYear();
            const finalDateString = `${year}-${month.slice(-2)}-${date.slice(-2)}`;

            axiosApi
                .get(`/dashboard/?date=${finalDateString}`)
                .then((response) => {
                    if (numberOfDays > 0) {
                        const data: [] = response.data;
                        data.reverse();
                        data.forEach((item: DashboardTravelData) => {
                            new Date(item.reservation.travel_start_date).getTime() ===
                                new Date(finalDateString).getTime() && arrayOfTravels.push(item);
                        });

                        if (numberOfDays === 1) {
                            arrayOfTravels.reverse();
                            setNextTravels(arrayOfTravels);
                        }
                        fetchNextDayData(dateString, arrayOfTravels, numberOfDays - 1);
                    }
                })
                .catch(() => {
                    addAlert("Error al recuperar las solicitudes.", "error");
                });
        },
        [addAlert]
    );

    /**
     * Gets data from a fixed numbers o days ahead
     */
    const nextDaysDataHandler = useCallback(
        (dateString: string) => {
            const arrayOfTravels: [] = [];
            const numberOfDaysAhead = 3;
            fetchNextDayData(dateString, arrayOfTravels, numberOfDaysAhead);
        },
        [fetchNextDayData]
    );

    /**
     * Get travel data by day
     */
    const fetchSelectedDayData = useCallback(
        (dateString) => {
            axiosApi
                .get(`/dashboard/?date=${dateString}`)
                .then((response) => {
                    const data = response.data;
                    setTodayTravels(data);
                    setCurrentDateToShow(getCurrentDate(dateString, "dayFirst", "slashed"));
                })
                .catch(() => {
                    addAlert("Error al recuperar las solicitudes.", "error");
                });
        },
        [addAlert]
    );

    /**
     * Get current pending requests
     */
    const fetchInbox = useCallback(() => {
        axiosApi
            .get("/inbox/?limit=5")
            .then((response) => {
                const data = (response.data.results as Array<ReservationRequestData>).map((req) => {
                    if (!req.contact) {
                        return req;
                    }

                    return {
                        ...req,
                        name: req.contact.name,
                        surname: req.contact.surname || "",
                        phone: req.contact.phone || "",
                        email: req.contact.email,
                    };
                });
                setReservationData(data);
            })
            .catch(() => {
                addAlert("Error al recuperar las solicitudes.", "error");
            });
    }, [addAlert]);

    /**
     * Add color to map when user selects any date in particular
     */
    const activateCountries = () => {
        const allCountries = mapRef.current?.querySelector(".rsm-geographies")?.children;

        allCountries &&
            [].slice.call(allCountries).forEach((country: HTMLElement) => {
                country.setAttribute("fill", "#F5F5F8");
            });

        todayTravels &&
            todayTravels.map((travel: DashboardTravelData) =>
                setTimeout(() => {
                    mapRef.current?.querySelector(`#${travel.country_code}`)?.setAttribute("fill", "#ffbe00");
                }, 0)
            );
    };
    activateCountries();

    /**
     * Post render actions
     */
    useEffect(() => {
        fetchCompany();
        const todayDate = getCurrentDate(null, null, null);
        setReservationData(null);
        fetchSelectedDayData(todayDate);
        nextDaysDataHandler(todayDate);
        fetchInbox();
    }, [fetchInbox, fetchSelectedDayData, nextDaysDataHandler, fetchCompany]);

    const loadingScreen = (
        <ScreenTemplate title="Dashboard">
            <div className="flex items-center justify-center h-full w-full">
                <Spinner />
            </div>
        </ScreenTemplate>
    );

    if (!todayTravels && !reservationData && !nextTravels) {
        return loadingScreen;
    }

    return (
        todayTravels &&
        nextTravels &&
        reservationData && (
            <ScreenTemplate title="Dashboard">
                <span className="mt-20 flex sm:hidden flex-1 text-4xl font-black font-sans text-blue-dark px-8 py-2">
                    Dashboard
                </span>
                <div className="grid lg:grid-cols-dashboardcalendar--responsive xl:grid-cols-dashboardcalendar gap-4 px-4 sm:px-8 pb-4 md:pb-8 pt-8">
                    <div className=" bg-white rounded-md shadow calendar-container w-74 h-full  pt-4 pl-6 pr-6 pb-6 border border-grey-light-3">
                        <div className="flex justify-between px-2 py-4">
                            <h2 className="text-blue text-xl">Selecciona fecha</h2>
                        </div>
                        <div className="flex items-center justify-center">
                            <Flatpickr
                                options={opts}
                                onChange={(selectedDates, dateStr) => {
                                    nextDaysDataHandler(dateStr);
                                    fetchSelectedDayData(dateStr);
                                }}
                            >
                                <div className="hidden">
                                    <TextInput
                                        id="calendar"
                                        type="text"
                                        label="mycalendar"
                                        placeholder="mycalendar"
                                        required
                                        disabled={false}
                                        // Component has to either be controlled or uncontrolled, can't be both at the same time.
                                        // For more info see https://reactjs.org/docs/forms.html#controlled-components
                                        defaultValue={undefined}
                                        value="hi"
                                    />
                                </div>
                            </Flatpickr>
                        </div>
                    </div>
                    <div
                        className={`bg-white rounded-md shadow p-4 border border-grey-light-3 ${
                            companyData?.contact_type === "provider" && "col-span-2"
                        }`}
                    >
                        <div className="flex justify-between p-4">
                            <div>
                                <h2 className="text-blue text-xl">Próximos viajes</h2>
                                <p className="text-grey-light-1 text-sm pt-1"> A partir del {currentDateToShow}</p>
                            </div>
                            <p className="text-3xl font-bold">{nextTravels?.length}</p>
                        </div>
                        <div className="relative h-[280px]">
                            <div className="overlay absolute h-[24px] left-0 right-3 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                            <div className="max-h-[280px] overflow-y-auto">
                                {nextTravels.length ? (
                                    nextTravels.map((travel: DashboardTravelData, index: number) => (
                                        <div
                                            className="flex px-4 py-4 hover:bg-yellow-light-1 rounded-md group justify-between items-center overflow-y-auto"
                                            key={index}
                                        >
                                            <div className="flex">
                                                {travel?.reservation?.clients[0]?.profile_image ? (
                                                    <Avatar
                                                        altText={
                                                            travel?.reservation?.clients[0]?.full_name &&
                                                            getInitials(travel.reservation.clients[0].full_name)
                                                        }
                                                        image={
                                                            travel?.reservation?.clients[0]?.profile_image &&
                                                            travel?.reservation?.clients[0]?.profile_image
                                                        }
                                                        size="xl"
                                                    />
                                                ) : (
                                                    <Avatar
                                                        altText={
                                                            travel?.reservation?.clients[0]?.full_name &&
                                                            getInitials(travel.reservation.clients[0].full_name)
                                                        }
                                                        size="xl"
                                                    />
                                                )}

                                                <div className="ml-6 grid">
                                                    <h3 className=" truncate min-w-[0] max-w-[100%]">
                                                        {travel.reservation?.clients[0]?.full_name}
                                                    </h3>
                                                    <p className="text-grey text-sm truncate min-w-[0] max-w-[100%]">
                                                        <span>
                                                            {travel?.location?.name}
                                                            {travel?.location?.name && ", "}
                                                        </span>
                                                        <span> {travel?.country?.name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="block group-hover:hidden justify-self-end flex pr-1 ml-2">
                                                <div className="text-grey-light-1 flex flex-col items-end">
                                                    <p className="text-md">
                                                        {getCurrentDate(
                                                            travel.reservation.travel_start_date,
                                                            "dayFirst",
                                                            "slashed"
                                                        )}
                                                    </p>
                                                    <p className="text-xs">
                                                        {travel.reservation.itinerary_days > 1
                                                            ? `${travel.reservation.itinerary_days} dias`
                                                            : `${travel.reservation.itinerary_days} dia`}
                                                        de viaje
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="hidden group-hover:block justify-self-end flex pr-2 ml-2">
                                                <div className="w-4">
                                                    <a href={`/quotations?exp=${travel.reservation.id}`}>
                                                        <IconButton
                                                            icon="visibility"
                                                            color="grey"
                                                            title="Ver petición"
                                                            extraClass="text-[18px]"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-grey-light-1 text-2xl flex h-full items-center text-center p-4">
                                        No hay viajes los próximos días
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {companyData?.contact_type === "provider" && (
                        <div className="bg-white rounded-md shadow p-4 border border-grey-light-3 lg:col-span-2 xl:col-span-1">
                            <div className="flex justify-between p-4">
                                <h2 className="text-blue text-xl">Solicitudes pendientes</h2>
                                <p className="text-3xl font-bold">{reservationData?.length}</p>
                            </div>
                            <div className="relative h-[258px]">
                                <div className="overlay absolute h-[30px] left-0 right-3 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                                <div className="max-h-[258px] overflow-y-auto">
                                    {reservationData?.map((reservationItem) => (
                                        <div
                                            className="flex px-4 py-4 hover:bg-yellow-light-1 rounded-md group justify-between items-center"
                                            key={reservationItem.id}
                                        >
                                            <div className="flex">
                                                <Avatar
                                                    altText={reservationItem.name && getInitials(reservationItem.name)}
                                                    image={reservationItem?.contact?.profile_image?.image}
                                                    size="xl"
                                                />
                                                <div className="ml-6 grid">
                                                    <h3 className="truncate min-w-[0] max-w-[100%]">
                                                        {reservationItem.name}
                                                    </h3>
                                                    <p className="text-grey text-sm truncate min-w-[0] max-w-[100%]">
                                                        {reservationItem.destinations[0].name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invisible group-hover:visible justify-self-end flex pr-4">
                                                <div className="w-4">
                                                    <a href={`/inbox?exp=${reservationItem.id}`}>
                                                        <IconButton
                                                            icon="visibility"
                                                            color="grey"
                                                            title="Ver petición"
                                                            extraClass="text-[18px]"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-span-12 bg-white rounded-md p-4 mx-4 sm:mx-8 rounded-md shadow mb-20 flex gap-8 border border-grey-light-3 h-[370px]">
                    <div className="basis-full md:basis-1/3 flex flex-col h-full">
                        <div className="p-4">
                            <p className="text-blue text-2xl">De viaje hoy</p>
                            <p className="text-grey-light-1 text-lg pt-2"> {currentDateToShow}</p>
                        </div>
                        <div className="relative h-[calc(100%-110px)]">
                            <div className="overlay absolute h-[30px] left-0 right-0 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                            <div className="h-full overflow-y-auto">
                                {todayTravels.length > 0 ? (
                                    todayTravels.map((travel: DashboardTravelData) => (
                                        <div
                                            className="flex px-4 py-4 hover:bg-yellow-light-1 rounded-md group justify-between items-center"
                                            key={travel.reservation.id}
                                        >
                                            <div className="flex">
                                                {travel?.reservation?.clients[0]?.profile_image ? (
                                                    <Avatar
                                                        altText={
                                                            travel?.reservation?.clients[0]?.full_name &&
                                                            getInitials(travel.reservation.clients[0].full_name)
                                                        }
                                                        image={
                                                            travel?.reservation?.clients[0]?.profile_image &&
                                                            travel?.reservation?.clients[0]?.profile_image
                                                        }
                                                        size="xl"
                                                    />
                                                ) : (
                                                    <Avatar
                                                        altText={
                                                            travel?.reservation?.clients[0]?.full_name &&
                                                            getInitials(travel.reservation.clients[0].full_name)
                                                        }
                                                        size="xl"
                                                    />
                                                )}
                                                <div className="ml-6 flex flex-col justify-center">
                                                    <h3>{travel.reservation?.clients[0]?.full_name}</h3>
                                                    <p className="text-grey text-sm ">
                                                        {travel?.location?.name && travel?.country?.name && (
                                                            <div>
                                                                <span>{travel?.location?.name},</span>
                                                                <span> {travel?.country?.name}</span>
                                                            </div>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invisible group-hover:visible justify-self-end flex pr-4">
                                                <div className="w-4">
                                                    <a href={`/quotations?exp=${travel.reservation.id}`}>
                                                        <IconButton
                                                            icon="visibility"
                                                            color="grey"
                                                            title="Ver petición"
                                                            extraClass="text-[18px]"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-grey-light-1 text-2xl flex h-full items-center text-center p-4">
                                        No hay reservas para ese día
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className=" hidden md:block md:basis-2/3 relative overflow-hidden" ref={mapRef}>
                        <div className="overlay absolute top-0 left-0 right-0 bottom-0 shadow-map pointer-events-none" />
                        <style>{".rsm-svg { object-fit:cover; height:100%;}"}</style>
                        <ComposableMap height={340}>
                            <ZoomableGroup center={[0, 20]} zoom={1}>
                                <Geographies geography={map}>
                                    {({ geographies }) =>
                                        geographies.map((geo) => (
                                            <Geography
                                                key={geo.rsmKey}
                                                id={geo.id}
                                                className={geo.name}
                                                geography={geo}
                                                fill="#F5F5F8"
                                                stroke="#CCC"
                                                strokeWidth={0.3}
                                                style={{
                                                    default: { outline: "none" },
                                                    hover: { outline: "none", fill: "#ffbe00" },
                                                    pressed: { outline: "none" },
                                                }}
                                            />
                                        ))
                                    }
                                </Geographies>
                            </ZoomableGroup>
                        </ComposableMap>
                    </div>
                </div>
            </ScreenTemplate>
        )
    );
};

export default DashboardScreen;
