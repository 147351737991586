import classNames from "classnames";
import React, { useRef, useState } from "react";
import Icon from "@mui/material/Icon";
import { truncate } from "../utils";
import { ModelRef } from "../types";
import ConfirmationModal from "./modals/ConfirmationModal";

export interface TravelDayProps {
    /**
     * Corresponding date of the itinerary
     */
    date: string | undefined;
    /**
     * Label for a particular day, to be shown below it.
     */
    label: string;
    /**
     * Whether this day is currently selected.
     */
    selected?: boolean;
    /**
     * Whether double check modal is disabled or not.
     */
    doublecheckDisabled: boolean;
    /**
     * This day's position in the itinerary.
     */
    position: number;
    /**
     * Whether the day is the last in its block.
     * It is used to determine whether the button to add a day should
     * appear, as we cannot add days within a block.
     */
    lastInBlock: boolean;
    /**
     * Optional click handler.
     */
    onClick?: () => void;
    /**
     * Will be called when clicking one of the add day buttons
     */
    onAdd?: () => void;
    /**
     * Will be called when deleting the current day
     */
    onDelete?: () => void;
    /**
     * Will be called when user disbales double check modal
     */
    onDisable: () => void;
    /**
     * Whether the current day can be modified or not (moved, added or deleted)
     */
    readOnly?: boolean;

    country?: ModelRef;

    location?: ModelRef;
}

const TravelDay: React.FC<TravelDayProps> = ({
    date,
    label,
    selected = false,
    position,
    lastInBlock,
    country,
    location,
    doublecheckDisabled,
    onClick,
    onAdd,
    onDelete,
    onDisable,
    readOnly,
}) => {
    const thisElement = useRef<HTMLInputElement>(null);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);

    let isLastChild = true;

    const isLastChildCheck = () => {
        if (thisElement.current) {
            thisElement.current.nextSibling ? (isLastChild = false) : (isLastChild = true);
        }
    };

    const handleDelete = () => {
        onDelete && onDelete();
        setIsConfirmationModalVisible(false);
    };

    isLastChildCheck();

    return (
        <div className="flex flex-col" ref={thisElement}>
            <div
                className={classNames(
                    selected ? "text-grey-dark-3" : "text-grey",
                    "absolute truncate -rotate-45 bottom-2 mb-16 origin-left h-16 ml-10"
                )}
            >
                <div>
                    <p className={selected ? "text-yellow text-sm" : "text-grey-light-2 text-sm"}>
                        {truncate(label || " Título sin asignar", 25) || "\u00A0"}
                    </p>

                    <p className={selected ? "text-xs ml-4" : "text-grey-light-2 text-xs ml-4"}>
                        <span>
                            {((country || location) &&
                                truncate(
                                    `${ location?.name ? `${location.name} /` : ""}  ${
                                        country?.name ? country.name : "País"
                                    }`,
                                    25
                                )) ||
                                "\u00A0"}
                        </span>
                    </p>
                </div>
            </div>
            <div className="relative flex flex-row items-center justify-center">
                <div className="relative flex flex-col items-center justify-center text-center mb-16">
                    <div className="relative group" title="Arrastra y suelta para reposicionar">
                        <div className="hidden group-hover:inline-block absolute top-[-5px] right-[-5px]">
                            <Icon
                                onClick={() => {
                                    doublecheckDisabled ? onDelete && onDelete() : setIsConfirmationModalVisible(true);
                                }}
                                className={classNames(
                                    selected && !readOnly ? "hidden group-hover:inline-block" : "hidden",
                                    "font-light cursor-pointer",
                                    "text-red h-4 w-4 focus:outline-none rounded-full",
                                    "transition ease-in-out hover:scale-125 duration-75"
                                )}
                            >
                                delete
                            </Icon>
                        </div>

                        <div
                            role="button"
                            tabIndex={0}
                            onClick={onClick}
                            onKeyDown={onClick}
                            className={classNames(
                                "flex items-center justify-center focus:outline-none border-yellow border-2 rounded-full h-12 w-12",
                                "items-center justify-center text-center",
                                selected ? "bg-yellow" : ""
                            )}
                        >
                            {position + 1}
                        </div>
                        {selected && <div className=" text-sm absolute bottom-[-28px] w-full">{date}</div>}
                        {!selected && (
                            <div className=" text-sm text-grey-light-1 absolute bottom-[-28px] w-full hidden group-hover:block">
                                {date}
                            </div>
                        )}
                    </div>
                </div>
                {selected && !readOnly && (
                    <div className="flex flex-row items-center justify-center text-center">
                        {lastInBlock && (
                            <>
                                <span className="border border-yellow w-12 mb-16" />
                                <button
                                    type="button"
                                    onClick={onAdd}
                                    className="focus:outline-none border-yellow border-2 rounded-full h-8 w-8 items-center flex  justify-center text-center text-grey mb-16"
                                >
                                    <Icon sx={{ fontSize: "16px" }}>add</Icon>
                                </button>
                            </>
                        )}
                    </div>
                )}
                <span className={`${isLastChild && "hidden"} border border-yellow w-12 mb-16`} />
            </div>
            <ConfirmationModal
                heading="Eliminar día"
                message="¿Estás seguro de que quieres eliminar este día?"
                acceptBtnText="Borrar"
                cancelBtnText="Cancelar"
                onDisable={onDisable}
                visible={isConfirmationModalVisible}
                disabled={doublecheckDisabled}
                visibleHandler={setIsConfirmationModalVisible}
                onAccept={handleDelete}
                onCancel={() => setIsConfirmationModalVisible(false)}
                size="sm"
            />
        </div>
    );
};

export default TravelDay;
