import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import MokenHeader from "./MokenHeader";
import { useAuth } from "../auth";
import { RootState } from "../store";

export interface ScreenTemplateProps {
    /**
     * Title of the screen.
     */
    title: string;

    /**
     * Content of the screen to show below the header.
     */
    children?: React.ReactNode;

    /**
     * Whether the screen should be blocked by unauthenticated users.
     * If true, unauthenticated users (or users whose credentials have expired) will be redirected
     * to the login page.
     */
    usersOnly?: boolean;

    /**
     * Whether the back button is visible or not.
     */
    backButtonVisible?: boolean;

    /**
     * What happens when the back button is clicked.
     */
    onBack?: () => void;
}

/**
 * Template for a platform screen, which wraps the header on top, and adds other things that are
 * common in every page like the legal notice, etc.
 */
const ScreenTemplate: React.FC<ScreenTemplateProps> = ({
    title,
    children,
    usersOnly = true,
    backButtonVisible = false,
    onBack,
}) => {
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const auth = useAuth();
    if (usersOnly && auth.user === null) {
        return <Navigate to="/login" />;
    }

    return (
        <div
            className={classNames(
                "block sm:fixed w-full right-0 top-0 sm:top-28 bottom-0 overflow-y-auto bg-white transition-left ease-in-out duration-200 no-scrollbar",
                isSidebarOpen ? "sm:left-80 sm:w-[calc(100%-20rem)]" : "xs:left-0 sm:left-20 sm:w-[calc(100%-100px)]"
            )}
        >
            <MokenHeader title={title} backButtonVisible={backButtonVisible} onBack={onBack} />
            {children}
            {/* TODO: Add other common things in every page, like the footer links for privacy info */}
        </div>
    );
};

export default ScreenTemplate;
