/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { SketchPicker, RGBColor } from "react-color";
import Button from "../Button";
import Modal from "../Modal";


export interface ColorPickerModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Handles which color palette item is managed by the color picker: primary, sceondary, etc...
     */
    colorType: string;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    onSave: (colorType: string, color:RGBColor) => void;
}

interface Colors {
    background: RGBColor;
}

/**
 * A simple modal whose only purpose is to show a list of values from a m2o field,
 * for the user to choose one or more from, with a search box to filter by name.
 *
 * A list with a single column is shown, which should be the value of the ModelRef.
 */
const ColorPickerModal: React.FC<ColorPickerModalProps> = ({
    visible,
    visibleHandler,
    onSave,
    colorType
}) => {
    const [pickerColor, setPickerColor] = useState<Colors>({background:{r:0, g:0, b:0, a:1}});
    const handleCloseSubModalEvent = (e: Event) => {
        e.stopPropagation();
        visibleHandler && visibleHandler(false);
    };

    // Add event handler only once, and remove it on componentDidUnmount. Implementors will have
    // to create a `myEvent = new Event('closeModal')` and then call `window.dispatchEvent(myEvent)`
    useEffect(() => {
        window.addEventListener("closeSubModal", handleCloseSubModalEvent);

        return () => window.removeEventListener("closeSubModal", handleCloseSubModalEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal size="sm" visible={visible} visibleHandler={visibleHandler}>
            <div className="flex flex-col w-full h-full p-8 justify-center">
                <div className=" flex justify-center">
                    <SketchPicker 
                        color={pickerColor && pickerColor.background}
                        width="224px"
                        onChangeComplete={ (e) => setPickerColor({...pickerColor,background:e.rgb})}
                    />
                </div>
                <div className="flex flex-row w-full items-center justify-between pt-8">
                    <div className="pr-2">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeSubModal"))}
                            title="Cerrar color picker"
                        />
                    </div>
                    <div>
                        <Button label="Guardar" onClick={() => onSave(colorType, pickerColor.background)} type="btn_yellow" title="Enviar invitación"/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ColorPickerModal;
