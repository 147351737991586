/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useRef, FocusEvent } from "react";
import classNames from "classnames";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import { Modifier, ModifierArguments, Options } from "@popperjs/core/lib/types";
import { DropdownItemProps } from "./DropdownItem";

export interface DropdownProps {
    /**
     * Element the dropdown menu will anchor to. Can be a button, avatar, input field, etc.
     */
    anchor: React.ReactElement;

    /**
     * Dropdown elements.
     */
    children: React.ReactElement<DropdownItemProps> | React.ReactElement<DropdownItemProps>[];

    /**
     * Placement of the popper dropdown. By default it is "bottom".
     */
    placement?: Placement;

    /**
     * Whether the dropdown should have the same width as the anchor.
     * Useful when having the same width is important for the style like with buttons or selection inputs.
     */
    sameWidth?: boolean;

    /**
     * Whether the dropdown is visible or not, should be managed by the parent with a state.
     */
    visible?: boolean;

    /**
     * Anchor's onClick function
     */
    onClick?: () => void;

    /**
     * Anchor's onFocus function
     */
    onFocus?: () => void;

    /**
     * Anchor's onBlur function
     */
    onBlur?: (e: FocusEvent<HTMLDivElement>) => void;

    /**
     * Anchor's onChange function
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Container for a list of dropdown elements.
 */
const Dropdown: React.FC<DropdownProps> = ({
    anchor,
    children,
    placement = "bottom",
    sameWidth = false,
    visible,
    onClick,
    onFocus,
    onBlur,
    onChange,
}) => {
    const referenceRef = useRef(null);
    const popperRef = useRef(null);
    const sameWidthModifier = useMemo(
        () => [
            {
                name: "sameWidth",
                enabled: true,
                phase: "beforeWrite",
                requires: ["computeStyles"],
                fn: ({ state }: ModifierArguments<Options>) => {
                    state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                effect: ({ state }: ModifierArguments<Options>) => {
                    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
                },
            },
        ],
        []
    ) as Partial<Modifier<unknown, object>>[] | undefined;
    const { styles, attributes, update } = usePopper(referenceRef.current, popperRef.current, {
        placement,
        modifiers: sameWidth ? sameWidthModifier : undefined,
    });

    const handleOnClick = () => {
        onClick && onClick();
        update && update();
    };

    const handleOnFocus = () => {
        onFocus && onFocus();
        update && update();
    };

    const handleOnBlur = (e: FocusEvent<HTMLDivElement>) => {
        onBlur && onBlur(e);
        update && update();
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
        update && update();
    };

    return (
        <div onBlur={handleOnBlur}>
            {React.cloneElement(anchor, {
                ref: referenceRef,
                onClick: handleOnClick,
                onFocus: handleOnFocus,
                onChange: handleOnChange,
            })}
            <div
                className={classNames(
                    visible && (children as React.ReactElement<DropdownItemProps>[]).length !== 0 ? "block" : "hidden",
                    "bg-white sm:py-2 z-40 rounded-lg border border-grey-light-3 mt-2 w-full sm:w-auto"
                )}
                ref={popperRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {children}
            </div>
        </div>
    );
};

export default Dropdown;
