import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Muicheckbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";

export interface CheckboxProps {
    /**
     * Identifies the checkbox, and allows it to be referenced when contained in a form.
     */
    id?: string;
    /**
     * Text describing the checkbox, shown beside it
     */
    label?: string;
    /**
     * Whether the checkbox is required in the form it lives in.
     */
    required?: boolean;
    /**
     * Whether the field is disabled.
     */
    disabled?: boolean;
    /**
     * Default setting for the checkbox (checked or unchecked).
     */
    defaultChecked?: boolean;
    checked?: boolean;
    /**
     * Optional onChange handler.
     */
    onChange?: (checked: boolean) => void;

    /**
     * Optional error message
     */
    errorMessage?: string;
}

/**
 * Checkbox field which the user can toggle on or off.
 */
const Checkbox: React.FC<CheckboxProps> = ({
    id,
    label,
    required,
    disabled,
    defaultChecked = false,
    checked = undefined,
    onChange,
    errorMessage,
}) => {
    const [isChecked, setIsChecked] = useState(checked !== undefined ? checked : defaultChecked);
    const theme = useTheme();
    // Change state if it was modified externally
    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <div className="relative">
            <div className="flex flex-row align-center">
                <div className="relative">
                    <Muicheckbox
                        id={id}
                        name={id}
                        required={required}
                        sx={{
                            color: theme.palette.mokenGreyLight_1.main,
                            "& .MuiSvgIcon-root": {
                                stroke: "#FFF",
                            },
                            paddingLeft: 0,
                        }}
                        disabled={disabled}
                        defaultChecked={defaultChecked}
                        checked={checked !== undefined ? checked : isChecked}
                        onChange={() => {
                            onChange && onChange(!isChecked);
                            if (checked === undefined) {
                                setIsChecked(!isChecked);
                            }
                        }}
                    />
                </div>
                <label
                    htmlFor={id}
                    className={classNames(
                        "relative top-0.5 ml-1 text-grey-light-1 text-sm flex items-center",
                        disabled ? "text-grey" : "text-blue-dark"
                    )}
                >
                    {label}
                </label>
            </div>
            {errorMessage && <p className="text-xs text-red absolute top-10">{errorMessage}</p>}
        </div>
    );
};

export default Checkbox;
