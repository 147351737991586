/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import TextInput from "../TextInput";

export interface InviteModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    onAccept: (name: string, surname: string, email: string) => void;
}

/**
 * A simple modal whose only purpose is to show a list of values from a m2o field,
 * for the user to choose one or more from, with a search box to filter by name.
 *
 * A list with a single column is shown, which should be the value of the ModelRef.
 */
const InviteModal: React.FC<InviteModalProps> = ({ visible, visibleHandler, onAccept }) => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");

    return (
        <Modal visible={visible} visibleHandler={visibleHandler}>
            <div className="flex flex-col w-full h-full p-8 space-y-5">
                <p>Invita a tus empleados a tu organización Moken</p>
                <p className="text-sm text-grey-dark-1">
                    Atención: cada empleado añadido aumenta el precio de tu suscripción
                </p>
                <div className="grid gap-4 grid-cols-2">
                    <TextInput
                        id="register-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Nombre"
                        required
                    />
                    <TextInput
                        id="register-surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        label="Apellido"
                    />
                    <div className="col-span-2">
                        <TextInput
                            id="register-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label="E-mail"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-row w-full items-center justify-end space-x-2">
                    <div className="pr-2">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeModal"))}
                            title="Cancelar invitación"
                        />
                    </div>
                    <div>
                        <Button
                            label="Enviar"
                            onClick={() => onAccept(name, surname, email)}
                            type="btn_yellow"
                            title="Enviar invitación"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InviteModal;
