/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../auth";
import axiosApi from "../axiosApi";
import Button from "../components/Button";
import Container from "../components/Container";
import CountrySelectModal from "../components/modals/CountrySelectModal";
import FileUploadZone from "../components/FileUploadZone";
import IconButton from "../components/IconButton";
import List from "../components/List/List";
import Many2one from "../components/Many2one";
import RadioGroup from "../components/RadioGroup";
import ScreenTemplate from "../components/ScreenTemplate";
import Spinner from "../components/Spinner";
import Tab from "../components/Tab/Tab";
import TabController from "../components/Tab/TabController";
import TagGroup from "../components/TagGroup";
import TextInput from "../components/TextInput";
import { Choice, ContactFullData, contactTypes, EmployeeData, ModelRef, OfficeData, PdfTemplate } from "../types";
import useAlert from "../useAlert";
import useModelRef from "../useModelRef";
import { formatDate, getContactTypeLabel, getInitials, getLowestInvalidId, getAvatarWithName } from "../utils";
import SelectionModal from "../components/modals/SelectionModal";
import CollapsableSection from "../components/CollapsableSection";
import TextEditModal from "../components/modals/TextEditModal";
import InviteModal from "../components/modals/InviteModal";
import CustomTemplateModal from "../components/modals/CustomTemplateModal";
import DeleteProfileModal from "../components/modals/DeleteProfileModal";
import ThumbnailSelect from "../components/ThumbnailSelect";
import Avatar from "../components/Avatar";

interface UserRow {
    id: number;
    name: React.ReactNode;
    position: string;
    actions: React.ReactNode;
}

const userFields: {
    key: keyof UserRow;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "position",
        title: "Posición",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

interface SettingProps {
    title: string;
    description?: string;
    setting: React.ReactNode;
}

const Setting: React.FC<SettingProps> = ({ title, description, setting }) => (
    <div className="flex flex-col w-full space-x-5 mt-14">
        <div className="">
            <p className="font-bold">{title}</p>
            {description && <p className="text-grey text-sm mt-2">{description}</p>}
            <div className="mt-6">{setting}</div>
        </div>
    </div>
);

const SettingsScreen: React.FC = () => {
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);
    const [isCountrySelectModalVisible, setIsCountrySelectModalVisible] = useState(false);
    const [isSpecialtySelectModalVisible, setIsSpecialtySelectModalVisible] = useState(false);
    const [isLanguageSelectModalVisible, setIsLanguageSelectModalVisible] = useState(false);
    const [isEmployeeInviteModalVisible, setIsEmployeeInviteModalVisible] = useState(false);
    const [isCustomTemplateModalVisible, setIsCustomTemplateModalVisible] = useState(false);
    const [countries, setCountries] = useState<Array<ModelRef>>([]);
    const [specialties, setSpecialties] = useState<Array<ModelRef>>([]);
    const [languages, setLanguages] = useState<Array<ModelRef>>([]);
    const [editedOffice, setEditedOffice] = useState<OfficeData | null>(null);
    const [pdfTemplates, setPdfTemplates] = useState<PdfTemplate[]>([]);
    const [billingUrl] = useState(new URL(process.env.REACT_APP_STRIPE_DASHBOARD_URL || "http://google.com"));
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeData | null>(null);
    const [delteProfileModalVisible, setdeleteProfileModalVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedUser, setSelectedUser] = useState<number | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (e: React.MouseEvent<HTMLElement>, employeeId: number) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setSelectedUser(employeeId);
    };
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const { getModelRef } = useModelRef();
    const { addAlert } = useAlert();
    const auth = useAuth();

    const screenTitle = "Configuración"; // i18n

    const orderEmployees = (companyDataToOrder: ContactFullData) => {
        companyDataToOrder &&
            companyDataToOrder.employees.forEach((employee, i) => {
                if (employee.email === companyData?.email) {
                    companyDataToOrder.employees.splice(i, 1);
                    companyDataToOrder.employees.unshift(employee);
                }
            });
        return companyDataToOrder;
    };

    const fetchCompany = useCallback(() => {
        if (auth.contact === null) return;

        axiosApi.get("/pdf_templates/").then((response) => {
            setPdfTemplates(response.data as PdfTemplate[]);
        });
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            const processedData = orderEmployees(data);
            setCompanyData(processedData);
        });
    }, []);

    const buildActions = (employeeId: number) => (
        <div>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <IconButton icon="more_vert" onClick={(e) => handleClick(e, employeeId)} extraClass="text-[20px]" />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClick={(e) => {
                    handleClose(e);
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        border: "1px solid #DDD",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: "''",
                            display: "block",
                            position: "absolute",
                            borderLeft: "1px solid #DDD",
                            borderTop: "1px solid #DDD",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    classes="flex justify-between"
                    onClick={() => {
                        companyData &&
                            axiosApi.delete(`user/delete/${selectedUser}/`).then(() => {
                                fetchCompany();
                            });
                    }}
                >
                    <span>
                        <IconButton icon="delete" extraClass="text-[22px] text-red" />
                    </span>
                    <span className="px-2">Eliminar usuario</span>
                </MenuItem>
            </Menu>
        </div>
    );

    const buildUserRow = (employee: EmployeeData) => {
        const position =
            employee.email === companyData?.email ? (
                <p className="text-xs text-yellow mt-2 border border-yellow inline-block py-1 px-2 rounded-sm">Admin</p>
            ) : (
                employee.role
            );
        return {
            id: employee.id,
            name: getAvatarWithName(employee.name),
            position,
            actions: companyData?.email !== employee.email && buildActions(employee.id),
        };
    };

    const handleSave = async () => {
        if (!companyData) return;

        let success = false;

        if (companyData.profile_image) {
            delete companyData.profile_image;
        }

        // Update company info
        await axiosApi
            .put("/contacts/company/", companyData)
            .then((updatedData) => {
                success = true;
                setCompanyData(updatedData.data);
            })
            .catch(() => addAlert("No se ha podido guardar la configuración.", "error"));

        if (success) {
            addAlert("Se ha guardado la información con éxito.", "success");
        }
    };

    const onAddOffice = () => {
        if (!companyData) return;

        const newOffices = companyData.offices.slice();
        newOffices.push({
            id: getLowestInvalidId(newOffices),
            office_name: "",
            address: "",
            city: "",
            country: "",
            info: "",
            vat: "",
        });
        setCompanyData({ ...companyData, offices: newOffices });
    };

    const onDeleteOffice = (id: number) => {
        if (!companyData) return;
        setCompanyData({ ...companyData, offices: companyData.offices.filter((office) => office.id !== id) });
    };

    const onAddEmailTemplate = () => {
        if (!companyData) return;

        const newTemplates = companyData.email_templates.slice();
        newTemplates.push({
            id: getLowestInvalidId(newTemplates),
            name: "Nueva plantilla",
            message: "",
            modified_datetime: "",
        });
        setCompanyData({ ...companyData, email_templates: newTemplates });
    };

    const onDeleteEmailTemplate = (id: number) => {
        if (!companyData) return;
        setCompanyData({ ...companyData, email_templates: companyData.email_templates.filter((t) => t.id !== id) });
    };

    const uploadImage = async (image: File) => {
        if (!companyData) return;

        const formData = new FormData();
        formData.append("name", image.name);
        formData.append("file", image, image.name);
        await axiosApi
            .post(`/contacts/${companyData.id}/upload_company_image/`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((r) => {
                setCompanyData({ ...companyData, profile_image: r.data });
                addAlert("Se ha cambiado la imagen de perfil.", "success");
            })
            .catch(() => addAlert("Hubo un error al cargar la imagen.", "error"));
    };

    const onDeleteProfile = () => {
        companyData &&
            // sustituir <contact_id> por companyData.id
            axiosApi.delete(`api/user/delete/<int:${companyData.id}>`);
        setdeleteProfileModalVisible(false);
    };

    useEffect(() => {
        if (!companyData) {
            fetchCompany();
        }
    });

    // Fetch m2o fields
    useEffect(() => {
        if (countries.length === 0) {
            getModelRef("countries").then((res) => {
                if (res) {
                    setCountries(res);
                }
            });
        }
        if (specialties.length === 0) {
            getModelRef("specialties").then((res) => {
                if (res) {
                    setSpecialties(res);
                }
            });
        }
        if (languages.length === 0) {
            getModelRef("languages").then((res) => {
                if (res) {
                    setLanguages(res);
                }
            });
        }
    });

    useEffect(() => {
        if (companyData && countries.length === 0) {
            getModelRef("countries").then(
                (retrievedCountries) => retrievedCountries && setCountries(retrievedCountries)
            );
        }
    }, [companyData, countries]);

    useEffect(() => {
        if (companyData) {
            billingUrl.searchParams.set("prefilled_email", companyData.email);
        }
    }, [companyData]);

    const profileTab = companyData && (
        <Tab name="Perfil y branding">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <div>
                    <Container>
                        <div className="flex flex-col w-full py-12 pt-0 sm:pt-10 ">
                            <h2 className="text-2xl font-black">Perfil público</h2>
                            <Setting
                                title="Nombre de perfil"
                                description="Personaliza el nombre de perfil que se mostrará a tus contactos y en las búsquedas públicas."
                                setting={
                                    <TextInput
                                        id="company_name"
                                        defaultValue={companyData.name}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, name: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                            <Setting
                                title="Razón fiscal"
                                description="Nombre fiscal de la empresa."
                                setting={
                                    <TextInput
                                        id="official_name"
                                        defaultValue={companyData.official_name}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, official_name: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                            <Setting
                                title="Logo de empresa"
                                description="Carga o modifica tu logo de empresa. Para mejores resultados, te recomendamos subir un archivo con fondo transparente."
                                setting={
                                    <div className="flex flex-col items-center justify-between space-y-2">
                                        <div className="border border-grey-light-2 h-1/2 w-full flex justify-center items-center p-2 rounded-md">
                                            <img
                                                src={companyData.profile_image?.image}
                                                alt="Company Logo"
                                                className="w-36 h-fit mr-5"
                                            />
                                        </div>

                                        <div className="w-full rounded-md">
                                            <FileUploadZone
                                                customText="Arrastra tu logo aqui o haz clic para buscarlo"
                                                accept="image/*"
                                                onChangeFiles={(images: File[]) => images && uploadImage(images[0])}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                            <Setting
                                title="Opciones de pago"
                                description="Elige tu plan de suscripción, cambia tu método de pago, revisa tus facturas, y más."
                                setting={
                                    <Button
                                        label="Opciones"
                                        type="btn_transparent"
                                        onClick={() => window.open(billingUrl, "_blank")}
                                    />
                                }
                            />
                            <Setting
                                title="Perfil público"
                                description="Define brevemente tu agencia en una o dos frases."
                                setting={
                                    <TextInput
                                        id="company_description"
                                        lines={2}
                                        defaultValue={companyData.description || ""}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, description: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                            <Setting
                                title="Destinos"
                                description="Indica qué destinos trabajas para mostrarlos en tu perfil público."
                                setting={
                                    <div>
                                        <div className="flex space-x-5 items-center">
                                            <TagGroup
                                                type="yellow"
                                                values={companyData.destinations}
                                                onDelete={(id: number) =>
                                                    setCompanyData({
                                                        ...companyData,
                                                        destinations: companyData.destinations.filter(
                                                            (d) => d.id !== id
                                                        ),
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="border border-blue-dark rounded-md shadow h-8 inline-block px-4 mt-4">
                                            <button
                                                title="Añadir destino"
                                                className="text-blue-dark text-xs"
                                                type="button"
                                                onClick={() => setIsCountrySelectModalVisible(true)}
                                            >
                                                Añadir destino
                                                <span className="text-lg relative top-0.5 left-0.5"> + </span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            />
                            <Setting
                                title="Define tu tipo de agencia"
                                description="Marca la opción que encaja con el perfil de tu agencia."
                                setting={
                                    <div className="flex items-center justify-between">
                                        <RadioGroup
                                            values={contactTypes
                                                .filter((ct) => ct !== "traveler")
                                                .map(
                                                    (contactType) =>
                                                        ({
                                                            value: contactType,
                                                            display: getContactTypeLabel(contactType),
                                                        } as Choice)
                                                )}
                                            selectedIdx={contactTypes
                                                .filter((ct) => ct !== "traveler")
                                                .findIndex((ct) => companyData.contact_type === ct)}
                                            onChange={(idx) =>
                                                setCompanyData({
                                                    ...companyData,
                                                    contact_type: contactTypes.filter((ct) => ct !== "traveler")[idx],
                                                })
                                            }
                                        />
                                    </div>
                                }
                            />
                            <Setting
                                title="Especializaciones"
                                description="Cuéntanos en qué es experta tu agencia."
                                setting={
                                    <div>
                                        <div className="flex space-x-5 items-center">
                                            <TagGroup
                                                type="yellow"
                                                values={companyData.specialties}
                                                onDelete={(id: number) =>
                                                    setCompanyData({
                                                        ...companyData,
                                                        specialties: companyData.specialties.filter((d) => d.id !== id),
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="border border-blue-dark rounded-md shadow h-8 inline-block px-4 mt-4">
                                            <button
                                                title="Añadir especialización"
                                                className="text-blue-dark text-xs"
                                                type="button"
                                                onClick={() => setIsSpecialtySelectModalVisible(true)}
                                            >
                                                Añadir especialización
                                                <span className="text-lg relative top-0.5 left-0.5"> + </span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            />
                            <Setting
                                title="Idiomas"
                                description="¿En qué idiomas puedes atender a tus clientes?"
                                setting={
                                    <div>
                                        <div className="space-x-5 items-center">
                                            <TagGroup
                                                type="yellow"
                                                values={companyData.languages}
                                                onDelete={(id: number) =>
                                                    setCompanyData({
                                                        ...companyData,
                                                        languages: companyData.languages.filter((d) => d.id !== id),
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="border border-blue-dark rounded-md shadow h-8 inline-block px-4 mt-4">
                                            <button
                                                title="Añadir idioma"
                                                className="text-blue-dark text-xs"
                                                type="button"
                                                onClick={() => setIsLanguageSelectModalVisible(true)}
                                            >
                                                Añadir idioma
                                                <span className="text-lg relative top-0.5 left-0.5"> + </span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            />
                            <Setting
                                title="Moneda por defecto"
                                description="El código de la divisa que se usará por defecto en nuevos presupuestos."
                                setting={
                                    <TextInput
                                        id="currency"
                                        defaultValue={companyData.config.currency || ""}
                                        maxLength={3}
                                        onChange={(ev) => {
                                            if (!companyData) return;
                                            const newConfig = companyData.config;
                                            newConfig.currency = ev.target.value;
                                            setCompanyData({ ...companyData, config: newConfig });
                                        }}
                                    />
                                }
                            />
                        </div>
                    </Container>
                </div>

                <div>
                    <Container>
                        <div className="flex flex-col w-full py-12 pt-10 space-y-10">
                            <h2 className="text-2xl font-black">Datos de contacto</h2>
                            <Setting
                                title="Email"
                                description="Este correo será por defecto el de comunicación de Moken y de recepción de solicitudes de viaje."
                                setting={
                                    <TextInput
                                        id="email"
                                        defaultValue={companyData.email || ""}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, email: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                            <Setting
                                title="Dirección"
                                description="La dirección donde se encuentra tu establecimiento."
                                setting={
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="col-span-2 w-full">
                                            <TextInput
                                                id="address"
                                                placeholder="Dirección"
                                                defaultValue={companyData.address || ""}
                                                onChange={(ev) =>
                                                    setCompanyData({ ...companyData, address: ev.currentTarget.value })
                                                }
                                            />
                                        </div>
                                        <TextInput
                                            id="city"
                                            placeholder="Ciudad"
                                            defaultValue={companyData.city || ""}
                                            onChange={(ev) =>
                                                setCompanyData({ ...companyData, city: ev.currentTarget.value })
                                            }
                                            data={countries}
                                        />
                                        <Many2one
                                            id="country"
                                            label="País"
                                            onBlur={(selected: ModelRef) =>
                                                setCompanyData({ ...companyData, country: selected })
                                            }
                                            defaultValue={companyData.country}
                                            onClick={(selected: ModelRef) =>
                                                setCompanyData({ ...companyData, country: selected })
                                            }
                                            data={countries}
                                        />
                                    </div>
                                }
                            />
                            <Setting
                                title="Teléfono"
                                description="Contacto a mostrar para tus clientes y proveedores."
                                setting={
                                    <TextInput
                                        id="phone"
                                        defaultValue={companyData.phone || ""}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, phone: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                            <Setting
                                title="Website"
                                description="Sitio web a mostrar en tu perfil público."
                                setting={
                                    <TextInput
                                        id="website"
                                        defaultValue={companyData.website || ""}
                                        onChange={(ev) =>
                                            setCompanyData({ ...companyData, website: ev.currentTarget.value })
                                        }
                                    />
                                }
                            />
                        </div>
                    </Container>
                </div>

                <div>
                    <Container>
                        <div className="flex flex-col w-full py-12 pt-10 space-y-10 pb-20">
                            <div className="flex flex-col divide-y divide-grey">
                                {companyData.offices.map((office, idx) => (
                                    <div key={office.id} className="py-10">
                                        <CollapsableSection
                                            title={`Datos fiscales ${office.office_name}`}
                                            buttonSide="left"
                                            titleSize="big"
                                            closedByDefault={office.id > 0}
                                            extraNode={
                                                <div className="flex items-center">
                                                    <IconButton
                                                        icon="edit"
                                                        title="Editar oficina"
                                                        onClick={() => setEditedOffice(office)}
                                                    />
                                                    <IconButton
                                                        icon="delete"
                                                        iconSize="27px"
                                                        title="Eliminar oficina"
                                                        color="red"
                                                        onClick={() => onDeleteOffice(office.id)}
                                                    />
                                                </div>
                                            }
                                        >
                                            <div className="flex flex-col w-full space-y-10">
                                                <Setting
                                                    title="NIF/VAT"
                                                    description="Número de identificación fiscal de la empresa."
                                                    setting={
                                                        <TextInput
                                                            id="office_vat"
                                                            defaultValue={office.vat}
                                                            onChange={(ev) => {
                                                                const newOffices = companyData.offices.slice();
                                                                newOffices[idx].vat = ev.currentTarget.value;
                                                                setCompanyData({ ...companyData, offices: newOffices });
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Setting
                                                    title="Dirección"
                                                    description="La dirección donde se encuentra tu establecimiento."
                                                    setting={
                                                        <div className="grid grid-cols-2 gap-2">
                                                            <div className="col-span-2 w-full">
                                                                <TextInput
                                                                    id="office?address"
                                                                    placeholder="Dirección"
                                                                    defaultValue={office.address}
                                                                    onChange={(ev) => {
                                                                        const newOffices = companyData.offices.slice();
                                                                        newOffices[idx].address =
                                                                            ev.currentTarget.value;
                                                                        setCompanyData({
                                                                            ...companyData,
                                                                            offices: newOffices,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <TextInput
                                                                id="city"
                                                                placeholder="Ciudad"
                                                                defaultValue={office.city}
                                                                onChange={(ev) => {
                                                                    const newOffices = companyData.offices.slice();
                                                                    newOffices[idx].city = ev.currentTarget.value;
                                                                    setCompanyData({
                                                                        ...companyData,
                                                                        offices: newOffices,
                                                                    });
                                                                }}
                                                            />
                                                            <TextInput
                                                                id="country"
                                                                placeholder="País"
                                                                defaultValue={office.country}
                                                                onChange={(ev) => {
                                                                    const newOffices = companyData.offices.slice();
                                                                    newOffices[idx].country = ev.currentTarget.value;
                                                                    setCompanyData({
                                                                        ...companyData,
                                                                        offices: newOffices,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </CollapsableSection>
                                    </div>
                                ))}
                            </div>
                            <div className="font-normal">
                                <Button label="Añadir oficina" icon="add" onClick={onAddOffice} type="btn_yellow" />
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="pb-20 pt-8">
                    <Container>
                        <h2 className="text-2xl font-black">Eliminar perfil</h2>
                        <p className="mt-4">Si eliminas tu perfil perderás todos los datos guardados.</p>
                        <button
                            onClick={() => setdeleteProfileModalVisible(true)}
                            className="underline text-red mt-8"
                            type="button"
                        >
                            Delete profile
                        </button>
                    </Container>
                </div>

                {/* Modals */}
                <CountrySelectModal
                    visible={isCountrySelectModalVisible}
                    visibleHandler={setIsCountrySelectModalVisible}
                    multiselection
                    onAccept={(selected: ModelRef[]) => {
                        const newDestinations = selected.filter(
                            (d) => !companyData.destinations.map((cd) => cd.id).includes(d.id)
                        );
                        if (newDestinations) {
                            const destinations = companyData.destinations.slice().concat(newDestinations);
                            setCompanyData({ ...companyData, destinations });
                        }
                    }}
                />
                <SelectionModal
                    visible={isSpecialtySelectModalVisible}
                    visibleHandler={setIsSpecialtySelectModalVisible}
                    multiselection
                    onAccept={(selected: ModelRef[]) => {
                        const newSpecialties = selected.filter(
                            (s) => !companyData.specialties.map((cs) => cs.id).includes(s.id)
                        );
                        if (newSpecialties) {
                            const specialtiesAux = companyData.specialties.slice().concat(newSpecialties);
                            setCompanyData({ ...companyData, specialties: specialtiesAux });
                        }
                    }}
                    searchBoxPlaceholder="Buscar especialidad"
                    title="Especialidades"
                    header="Especialidad"
                    values={specialties}
                />
                <SelectionModal
                    visible={isLanguageSelectModalVisible}
                    visibleHandler={setIsLanguageSelectModalVisible}
                    multiselection
                    onAccept={(selected: ModelRef[]) => {
                        const newLanguages = selected.filter(
                            (l) => !companyData.languages.map((cl) => cl.id).includes(l.id)
                        );
                        if (newLanguages) {
                            const languagesAux = companyData.languages.slice().concat(newLanguages);
                            setCompanyData({ ...companyData, languages: languagesAux });
                        }
                    }}
                    searchBoxPlaceholder="Buscar idioma"
                    title="Idiomas"
                    header="Idioma"
                    values={languages}
                />
                <TextEditModal
                    visible={editedOffice !== null}
                    visibleHandler={(visible) => {
                        if (!visible) setEditedOffice(null);
                    }}
                    onAccept={(text) => {
                        if (!editedOffice) return;
                        const newOffices = companyData.offices.slice();
                        const idx = newOffices.findIndex((office) => office.id === editedOffice.id);
                        if (idx >= 0) {
                            newOffices[idx].office_name = text;
                            setCompanyData({
                                ...companyData,
                                offices: newOffices,
                            });
                        }
                        setEditedOffice(null);
                    }}
                    defaultValue={editedOffice ? editedOffice.office_name : ""}
                />
                <DeleteProfileModal
                    visible={delteProfileModalVisible}
                    onDelete={onDeleteProfile}
                    visibleHandler={setdeleteProfileModalVisible}
                />
            </div>
        </Tab>
    );

    const usersTab = companyData && (
        <Tab name="Usuarios">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <Container>
                    <div className="py-12">
                        <div className="font-bold">
                            <p className="text-2xl place-self-center">Todos los usuarios</p>
                        </div>
                        <p className="text-xs text-yellow mt-2 border border-yellow inline-block py-1 px-2 rounded-sm">
                            {companyData?.employees.length} total
                        </p>
                        <div className="mt-6">
                            <List
                                objects={companyData.employees
                                    .filter((employee) => employee.email !== companyData?.email)
                                    .map((employee) => buildUserRow(employee))}
                                fields={userFields}
                                listType="userlist"
                                onRowClick={(id: number) => {
                                    const clickedEmployee = companyData.employees.find((e) => e.id === id);
                                    if (clickedEmployee) {
                                        setSelectedEmployee(clickedEmployee);
                                    }
                                }}
                            />
                        </div>
                        <div className=" mt-6">
                            <Button
                                label="Añadir"
                                icon="add"
                                onClick={() => setIsEmployeeInviteModalVisible(true)}
                                type="btn_yellow"
                                title="Añadir usuario"
                            />
                        </div>
                    </div>
                </Container>
                <InviteModal
                    visible={isEmployeeInviteModalVisible}
                    visibleHandler={setIsEmployeeInviteModalVisible}
                    onAccept={(name, surname, email) => {
                        let isEmployeeAlreadyRegistered = false;
                        if (!name || !email) {
                            addAlert("Es necesario rellenar el nombre y el e-mail del usuario.", "error");
                            return;
                        }

                        if (email === companyData.email) {
                            addAlert("Ese email está reservado para el usuario principal", "error");
                            return;
                        }
                        companyData.employees.forEach((employee) => {
                            if (employee.email === email) {
                                addAlert("Ese email pertenece a un usuario registrado", "error");
                                isEmployeeAlreadyRegistered = true;
                            }
                        });
                        if (isEmployeeAlreadyRegistered) {
                            return;
                        }
                        auth.invite(name, surname, email).then((inviteStatus) => {
                            if (inviteStatus.error) {
                                addAlert(inviteStatus.error, "error");
                            } else {
                                window.dispatchEvent(new Event("closeModal"));
                                setCompanyData(null);
                                addAlert("Invitación enviada con éxito", "success");
                            }
                        });
                    }}
                />
            </div>
        </Tab>
    );

    const templatesTab = (
        <Tab name="Plantillas">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <div className="flex flex-col">
                    <Container>
                        <div className="flex flex-col w-full py-12">
                            <p className="font-bold text-2xl pl-1">Plantillas de itinerario</p>
                            <div className="pt-8 pl-1">
                                <Button
                                    label="Añadir plantilla personalizada"
                                    icon="add"
                                    onClick={() => setIsCustomTemplateModalVisible(true)}
                                    type="btn_yellow"
                                    title="Añadir nueva plantilla"
                                />
                            </div>

                            <div className="pt-6 px-1 grid grid-cols-1 xl:grid-cols-2 gap-5 place-items-center">
                                {pdfTemplates &&
                                    pdfTemplates.map((template) => (
                                        <ThumbnailSelect
                                            key={template.id}
                                            image={
                                                template.is_moken_template
                                                    ? template.thumbnail
                                                    : "./resources/templates/blank_custom_template.png"
                                            }
                                            isMokenTemplate={template.is_moken_template}
                                            onDelete={async () => {
                                                await axiosApi.delete(`/pdf_templates/${template.id}/`).catch(() => {
                                                    addAlert("No se ha podido eliminar la plantilla.", "error");
                                                });
                                                await axiosApi.get("/pdf_templates/").then((response) => {
                                                    setPdfTemplates(response.data as PdfTemplate[]);
                                                });
                                            }}
                                            customName={!template.is_moken_template ? template.name : ""}
                                            altText={template.name}
                                            selected={template.id === companyData?.config.pdf_template?.id}
                                            onClick={() => {
                                                const clickedTemplate = pdfTemplates.find((t) => t.id === template.id);
                                                if (!companyData || !clickedTemplate) return;
                                                const newConfig = companyData.config;
                                                newConfig.pdf_template = clickedTemplate;
                                                setCompanyData({ ...companyData, config: newConfig });
                                            }}
                                        />
                                    ))}
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="flex flex-col">
                    <Container>
                        <div className="flex flex-col w-full py-12 pt-10 space-y-10">
                            <p className="font-bold text-2xl">Plantillas de mail</p>
                            {companyData &&
                                companyData.email_templates.map((template, idx) => (
                                    <CollapsableSection
                                        title={template.name}
                                        buttonSide="left"
                                        key={template.id}
                                        closedByDefault={template.id > 0}
                                    >
                                        <div className=" space-y-5 w-full mt-5">
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className="flex-1 col-span-6">
                                                    <TextInput
                                                        id="email_template_name"
                                                        type="text"
                                                        defaultValue={template.name}
                                                        label="Nombre"
                                                        onChange={(ev) => {
                                                            const newTemplates = companyData.email_templates.slice();
                                                            newTemplates[idx].name = ev.currentTarget.value;
                                                            setCompanyData({
                                                                ...companyData,
                                                                email_templates: newTemplates,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-5">
                                                    <TextInput
                                                        id="email_template_modified"
                                                        disabled
                                                        label="Última modificación"
                                                        defaultValue={
                                                            template.modified_datetime
                                                                ? formatDate(new Date(template.modified_datetime))
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="min-w-max col-span-1 border border-red flex justify-center rounded-md">
                                                    <IconButton
                                                        icon="delete"
                                                        iconSize="27px"
                                                        title="Eliminar plantilla"
                                                        extraClass="text-[22px]"
                                                        color="red"
                                                        onClick={() => onDeleteEmailTemplate(template.id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <TextInput
                                                    id="email_template_message"
                                                    type="text"
                                                    defaultValue={template.message}
                                                    label="Mensaje"
                                                    lines={12}
                                                    onChange={(ev) => {
                                                        const newTemplates = companyData.email_templates.slice();
                                                        newTemplates[idx].message = ev.currentTarget.value;
                                                        setCompanyData({
                                                            ...companyData,
                                                            email_templates: newTemplates,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CollapsableSection>
                                ))}
                            <div>
                                <Button
                                    label="Añadir plantilla"
                                    icon="add"
                                    onClick={onAddEmailTemplate}
                                    type="btn_yellow"
                                    title="Añadir nueva plantilla"
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <CustomTemplateModal
                visible={isCustomTemplateModalVisible}
                pdfTemplates={pdfTemplates}
                visibleHandler={setIsCustomTemplateModalVisible}
                onAccept={async (newCustomTemplate: PdfTemplate) => {
                    if (!newCustomTemplate) return;
                    await axiosApi
                        .post("/pdf_templates/", newCustomTemplate, {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        })
                        .then(() => {
                            setPdfTemplates([...pdfTemplates, newCustomTemplate]);
                        })
                        .catch(() => addAlert("No se ha podido guardar la configuración.", "error"));

                    setIsCustomTemplateModalVisible(false);
                }}
            />
        </Tab>
    );

    const termsTab = companyData && (
        <Tab name="Términos">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <CollapsableSection title="1. Incluido / No incluido" buttonSide="left" titleSize="big" arrowSize="md">
                    <div className="flex  w-full justify-between mt-5 gap-4 flex-wrap md:flex-nowrap">
                        <div className="w-full">
                            <TextInput
                                id="included"
                                type="text"
                                label="Incluido"
                                maxLength={7800}
                                showMax
                                defaultValue={companyData.config.included}
                                lines={9}
                                onChange={(ev) => {
                                    const newConfig = companyData.config;
                                    newConfig.included = ev.target.value;
                                    setCompanyData({ ...companyData, config: newConfig });
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <TextInput
                                id="not_included"
                                type="text"
                                label="No incluido"
                                maxLength={7800}
                                showMax
                                defaultValue={companyData.config.not_included}
                                lines={9}
                                onChange={(ev) => {
                                    const newConfig = companyData.config;
                                    newConfig.not_included = ev.target.value;
                                    setCompanyData({ ...companyData, config: newConfig });
                                }}
                            />
                        </div>
                    </div>
                </CollapsableSection>
                <div className="pt-12">
                    <CollapsableSection
                        title="2. Condiciones de cancelación"
                        buttonSide="left"
                        titleSize="big"
                        arrowSize="md"
                    >
                        <div className="flex w-full mt-5">
                            <div className="w-full">
                                <TextInput
                                    id="cancel_conditions"
                                    type="text"
                                    defaultValue={companyData.config.cancellation_policy}
                                    lines={9}
                                    maxLength={3800}
                                    showMax
                                    onChange={(ev) => {
                                        const newConfig = companyData.config;
                                        newConfig.cancellation_policy = ev.target.value;
                                        setCompanyData({ ...companyData, config: newConfig });
                                    }}
                                />
                            </div>
                        </div>
                    </CollapsableSection>
                </div>

                {companyData.config.extra_policies.map((policy, idx) => (
                    <div className="flex flex-col space-y-2 w-full mt-5" key={policy.id}>
                        <div className="items-center space-x-5 grid grid-cols-12">
                            <div className="w-full col-span-11">
                                <TextInput
                                    id="policy_title"
                                    type="text"
                                    defaultValue={policy.title}
                                    onChange={(ev) => {
                                        const newConfig = companyData.config;
                                        newConfig.extra_policies[idx].title = ev.currentTarget.value;
                                        setCompanyData({
                                            ...companyData,
                                            config: newConfig,
                                        });
                                    }}
                                />
                            </div>
                            <div className="min-w-max border border-red rounded-md col-span-1 flex justify-center items-center">
                                <IconButton
                                    icon="delete"
                                    iconSize="27px"
                                    color="red"
                                    onClick={() => {
                                        const newConfig = companyData.config;
                                        newConfig.extra_policies = companyData.config.extra_policies.filter(
                                            (p) => p.id !== policy.id
                                        );
                                        setCompanyData({
                                            ...companyData,
                                            config: newConfig,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <TextInput
                                id="policy_text"
                                type="text"
                                defaultValue={policy.text}
                                lines={12}
                                onChange={(ev) => {
                                    const newConfig = companyData.config;
                                    newConfig.extra_policies[idx].text = ev.currentTarget.value;
                                    setCompanyData({
                                        ...companyData,
                                        config: newConfig,
                                    });
                                }}
                            />
                        </div>
                    </div>
                ))}

                <div className="w-full pt-6 flex justify-end mb-20">
                    <Button
                        label="Añadir"
                        icon="add"
                        onClick={() => {
                            const newConfig = companyData.config;
                            newConfig.extra_policies.push({
                                id: getLowestInvalidId(newConfig.extra_policies),
                                title: "",
                                text: "",
                            });
                            setCompanyData({
                                ...companyData,
                                config: newConfig,
                            });
                        }}
                        type="btn_yellow"
                        title="Añadir término"
                    />
                </div>
            </div>
        </Tab>
    );

    const loadingScreen = (
        <ScreenTemplate title={screenTitle}>
            <Container>
                <div className="flex items-center justify-center h-full w-full">
                    <Spinner />
                </div>
            </Container>
        </ScreenTemplate>
    );

    if (!companyData) {
        return loadingScreen;
    }

    const employeeFullName = selectedEmployee ? `${selectedEmployee.name} ${selectedEmployee.surname}` : "";

    const employeeEditScreen = selectedEmployee && (
        <div>
            <Container>
                <div className="flex flex-col w-full py-12 pt-10 space-y-10 p-8">
                    <h1 className="text-2xl font-bold">Editar usuario</h1>
                    <div className="flex space-x-5 items-center mt-8">
                        <Avatar image={selectedEmployee.profile_image?.image} altText={getInitials(employeeFullName)} />
                        <p className="text-2xl">{employeeFullName}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <TextInput
                                type="text"
                                label="Nombre"
                                defaultValue={selectedEmployee.name}
                                onChange={(ev) => {
                                    const newEmployees = companyData.employees.slice();
                                    const idx = newEmployees.findIndex((e) => e.id === selectedEmployee.id);
                                    newEmployees[idx].name = ev.currentTarget.value;
                                    setCompanyData({
                                        ...companyData,
                                        employees: newEmployees,
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <TextInput
                                type="text"
                                label="Apellido"
                                defaultValue={selectedEmployee.surname}
                                onChange={(ev) => {
                                    const newEmployees = companyData.employees.slice();
                                    const idx = newEmployees.findIndex((e) => e.id === selectedEmployee.id);
                                    newEmployees[idx].surname = ev.currentTarget.value;
                                    setCompanyData({
                                        ...companyData,
                                        employees: newEmployees,
                                    });
                                }}
                            />
                        </div>
                        <div className="mt-4">
                            <TextInput
                                type="text"
                                label="Posición"
                                defaultValue={selectedEmployee.role}
                                onChange={(ev) => {
                                    const newEmployees = companyData.employees.slice();
                                    const idx = newEmployees.findIndex((e) => e.id === selectedEmployee.id);
                                    newEmployees[idx].role = ev.currentTarget.value;
                                    setCompanyData({
                                        ...companyData,
                                        employees: newEmployees,
                                    });
                                }}
                            />
                        </div>

                        <div className="mt-4">
                            <TextInput type="text" label="Email" defaultValue={selectedEmployee.email} disabled />
                        </div>
                        <div className="mt-4">
                            <TextInput
                                type="text"
                                label="Teléfono"
                                defaultValue={selectedEmployee.phone}
                                onChange={(ev) => {
                                    const newEmployees = companyData.employees.slice();
                                    const idx = newEmployees.findIndex((e) => e.id === selectedEmployee.id);
                                    newEmployees[idx].phone = ev.currentTarget.value;
                                    setCompanyData({
                                        ...companyData,
                                        employees: newEmployees,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );

    const settingsScreen = (
        <div className="flex flex-col w-full h-full overflow-y-hidden">
            <p className="block sm:hidden text-[2.2rem] font-black font-sans text-blue-dark mt-28 px-8">
                Configuración
            </p>
            <div className="flex w-full items-center justify-end px-8">
                <div className="w-full sm:w-auto h-full mt-8 sm:mt-0">
                    <Button
                        label="Guardar"
                        type="btn_dark"
                        onClick={handleSave}
                        title="Guardar configuración"
                        extraClass="w-full h-[56px] sm:h-auto sm:w-auto"
                    />
                </div>
            </div>

            <div className="flex w-full h-full">
                <div className="w-full">
                    <div className="max-w-[1024px] px-8 sm:h-[calc(100vh-180px)] mt-8">
                        <TabController
                            lockReason="Guarda los cambios en el nuevo día antes de cambiar de pestaña."
                            defaultTab={selectedTab}
                            onChangeTab={setSelectedTab}
                        >
                            {profileTab}
                            {usersTab}
                            {templatesTab}
                            {termsTab}
                        </TabController>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <ScreenTemplate
            title={screenTitle}
            onBack={() => setSelectedEmployee(null)}
            backButtonVisible={selectedEmployee !== null}
        >
            <div className="flex flex-col h-full bg-white">
                {selectedEmployee ? employeeEditScreen : settingsScreen}
            </div>
        </ScreenTemplate>
    );
};

export default SettingsScreen;
