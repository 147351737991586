/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import download from "downloadjs";
import Button from "../Button";
import Modal from "../Modal";
import SearchBox from "../SearchBox";
import List from "../List/List";
import axiosApi from "../../axiosApi";
import IconButton from "../IconButton";
import LoadingModal from "./LoadingModal";
import Tag from "../Tag";
import { ClientTemplate } from "../../types";

const PAGE_SIZE = 10;

interface ClientTemplateRow {
    id: number;
    provider_name: string;
    title: string;
    itinerary_days: number;
    destinations: React.ReactNode;
    travel_type: string | React.ReactNode;
    actions: React.ReactNode;
}

const clientTemplateFields: {
    key: keyof ClientTemplateRow;
    title: string;
}[] = [
    {
        key: "provider_name",
        title: "Proveedor",
    },
    {
        key: "title",
        title: "Título",
    },
    {
        key: "itinerary_days",
        title: "Días",
    },
    {
        key: "destinations",
        title: "Destino",
    },
    {
        key: "travel_type",
        title: "Tipo de viaje",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

export interface ImportTemplateModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Callback for importing the template's itinerary into the parent reservation.
     */
    onImport: (selected: number) => void;
}

const ImportTemplateModal: React.FC<ImportTemplateModalProps> = ({ visible, visibleHandler, onImport }) => {
    const [templates, setTemplates] = useState<ClientTemplate[] | null>(null);
    const [currentPage, setCurrentPage] = useState(1); // For pagination, starts at 1
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState<number | undefined>(undefined);
    const [searchBoxContent, setSearchBoxContent] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [isDownloadingPdfModalVisible, setIsDownloadingPdfModalVisible] = useState(false);

    const onImportHandler = () => {
        if (templates && selectedTemplate) {
            onImport(selectedTemplate);
        }
        window.dispatchEvent(new Event("closeModal"));
    };

    const fetchTemplates = useCallback(() => {
        // Get the templates from connected clients
        axiosApi
            .get(
                `/client_templates/?limit=${PAGE_SIZE}&offset=${(currentPage - 1) * PAGE_SIZE}${
                    searchQuery ? `&query=${searchQuery}` : ""
                }`
            )
            .then((response) => {
                setTotalRecords(response.data.count);
                setTemplates((currentPage === 1 || !templates ? [] : templates).concat(response.data.results));
                setCurrentPage(currentPage + 1);
            });
    }, [currentPage, searchQuery]);

    const buildClientTemplateRow = (template: ClientTemplate): ClientTemplateRow => {
        const destinations = (
            <div className="flex space-x-1 items-center justify-center">
                {template.countries?.length === 0 && <p className=" text-grey-light-2">-</p>}
                {template.countries?.length > 0 && <Tag label={template.countries[0]} type="yellow" />}
                {template.countries?.length > 1 && <span>{`+${template.countries.length - 1}`}</span>}
            </div>
        );

        const travelType = (
            <span className="flex row-flex">
                <Tag label={template.travel_type} type="yellow" />
            </span>
        );
        const actions = (
            <div className="flex space-x-4">
                <IconButton
                    icon="download"
                    onClick={() => {
                        setIsDownloadingPdfModalVisible(true);
                        axiosApi
                            .get(`/templates/${template.id}/print/`, {
                                responseType: "blob",
                            })
                            .then((response) => {
                                download(response.data, template.title, response.headers["content-type"]);
                                setIsDownloadingPdfModalVisible(false);
                            })
                            .catch(() => setIsDownloadingPdfModalVisible(false));
                    }}
                />
            </div>
        );

        return {
            destinations,
            id: template.id,
            itinerary_days: template.itinerary_days,
            provider_name: template.provider_name,
            actions,
            title: template.title,
            travel_type: travelType,
        };
    };

    // When a search query is submitted, reset the list and re-fetch clients using the query
    useEffect(() => {
        setCurrentPage(1);
        setTemplates(null);
    }, [searchQuery]);

    // Initial load of clients
    useEffect(() => {
        if (currentPage === 1) {
            fetchTemplates();
        }
    }, [currentPage]);

    useEffect(() => {
        if (visible) {
            // Reset selected rows to the default ones
            setSelectedTemplate(undefined);
        }
    }, [visible, setSelectedTemplate]);

    return (
        <Modal visible={visible} visibleHandler={visibleHandler}>
            <div className="flex flex-col items-center justify-center h-full p-4 sm:p-8 space-y-5">
                <h2 className="text-2xl font-bold font-sans">Selecciona un programa</h2>
                <div className="w-full mt-5">
                    <SearchBox
                        placeholder="Buscar proveedor, título o destino..."
                        onChange={(s) => setSearchBoxContent(s)}
                        onSubmit={() => setSearchQuery(searchBoxContent)}
                    />
                </div>

                <div className="h-96 overflow-auto w-full" id="templateListContainer">
                    {templates && (
                        <List
                            fields={clientTemplateFields}
                            listType="importItinerarylist"
                            objects={templates.map((template) => buildClientTemplateRow(template))}
                            selectedRows={selectedTemplate !== undefined ? new Set([selectedTemplate]) : undefined}
                            onRowClick={(id: number) => setSelectedTemplate(id)}
                            onScrollToEnd={fetchTemplates}
                            hasMore={templates.length < totalRecords}
                            scrollableTarget="templateListContainer"
                        />
                    )}
                </div>
                <div className="flex flex-col-reverse sm:flex-row w-full justify-end gap-2 flex-wrap">
                    <div className="w-full sm:w-auto">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeModal"))}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                    <div className="w-full sm:w-auto">
                        <Button
                            type="btn_dark"
                            label="Importar"
                            onClick={onImportHandler}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                </div>
            </div>

            {/* Submodals */}
            <LoadingModal
                visible={isDownloadingPdfModalVisible}
                text="El PDF se está generando. La descarga comenzará en breve..."
            />
        </Modal>
    );
};

export default ImportTemplateModal;
