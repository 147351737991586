export const contactTypes = ["traveler", "retail_agency", "wholesale_agency", "provider"];
export type ContactType = typeof contactTypes[number];
export type ReservationState =
    | "new"
    | "in_progress"
    | "sent"
    | "confirmed"
    | "completed"
    | "lost"
    | "cancelled"
    | "res_in_progress"
    | "res_sent";
export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};
export type ModelRef = {
    id: number;
    name: string;
};

export type NotificactionModelRef = {
    id: number;
    model: string;
};
export type Choice = {
    value: string;
    display: string;
    thumbnail?: string;
};
export type Location = {
    id: number;
    name: string;
    country: number;
    default_image: MokenImage;
};
export type AlertType = "info" | "warning" | "error" | "success";
export type EmployeeData = {
    id: number;
    name: string;
    surname?: string;
    full_name: string;
    role?: string;
    email: string;
    phone?: string;
    profile_image?: MokenImage;
};
export type MokenImage = {
    id: number;
    image: string; // returned as an URL, either to the server or Unsplash
    keyword?: string;
    name?: string;
    author?: string;
    author_url?: string;
    is_moken_image: boolean;
    is_unsplash?: boolean;
    is_provider?: boolean;
    author_image?: MokenImage;
};
export type OfficeData = {
    id: number;
    office_name: string;
    vat: string;
    address: string;
    city: string;
    country: string;
    info: string;
};
export type PdfTemplate = {
    id: number;
    name: string;
    thumbnail: string;
    primary_color: string;
    accent_color: string;
    font: string;
    is_moken_template: boolean;
    template: string;
};
export type CompanyConfig = {
    id: number;
    pdf_template?: PdfTemplate;
    currency: string;
    included: string;
    not_included: string;
    cancellation_policy: string;
    extra_policies: ExtraPolicy[];
};
export type ContactMinimalData = {
    id: number;
    full_name: string;
    profile_image?: MokenImage;
};
export type ContactData = {
    id: number;
    full_name: string;
    company_type: ContactType;
    name: string;
    official_name?: string;
    surname?: string;
    initials: string;
    email: string;
    contact_type: ContactType;
    profile_image?: MokenImage;
    average_rating: number;
    num_ratings: number;
    is_user: boolean;
    address?: string;
    traveler_type?: string;
    city?: string;
    country?: ModelRef;
    date_of_birth?: Date;
    phone?: string;
    vat?: string;
    interests: Array<ModelRef>;
    specialties: Array<ModelRef>;
    languages: Array<ModelRef>;
    destinations: Array<ModelRef>;
    website?: string;
    description?: string;
    employees: Array<EmployeeData>;
    requests: Array<ContactData>;
    common_connections?: Array<ContactData>;
    offices: OfficeData[];
};
export interface ContactFullData extends ContactData {
    config: CompanyConfig;
    email_templates: EmailTemplate[];
}
export type AttachmentData = {
    id: number;
    name: string;
    fs_file: string;
    size: number; // in bytes
    timestamp: Date;
};
export interface AttachmentRow {
    id: number;
    name: string;
    size: string;
    timestamp: string;
    actions: React.ReactNode;
}
export interface TravelDayData {
    id: number;
    title: string;
    position: number;
    location?: ModelRef;
    country?: ModelRef;
    breakfast: boolean;
    lunch: boolean;
    dinner: boolean;
    all_included: boolean;
    description: string;
    important_notes: string;
    accommodation_location?: string;
    standard_accommodation?: string;
    superior_accommodation?: string;
    luxury_accommodation?: string;
    standard_accommodation_link?: string;
    superior_accommodation_link?: string;
    luxury_accommodation_link?: string;
    image?: MokenImage;
    provider_image?: MokenImage;
}
export interface DayBlockData {
    id: number;
    object_id: number;
    position: number;
    days: TravelDayData[];
    provider?: ContactMinimalData;
    title: string;
    template_request?: number;
    version: number;
}
export interface ServiceLine {
    id: number;
    sales_order: number;
    name: string;
    price: string;
    superior_price: string;
    luxury_price: string;
    selected_package: string;
    markup: number;
}
export interface ExtraLine {
    id: number;
    sales_order: number;
    name: string;
    price: string;
    markup: number;
    observations: string;
}
export interface DiscountLine {
    id: number;
    sales_order: number;
    name: string;
    price: string;
    observations: string;
}
export interface SalesOrder {
    id: number;
    services: Array<ServiceLine>;
    extras: Array<ExtraLine>;
    discounts: Array<DiscountLine>;
}
export interface ExtraPolicy {
    id: number;
    title: string;
    text: string;
}

/**
 * Continer for ProviderRequest.
 */
export interface ProviderRequest {
    id: number;
    name: string;
    profile_image: MokenImage;
}

/**
 * Continer for TemplateRequestData.
 */
export interface TemplateRequestContainer {
    provider: ProviderRequest;
    requests: Array<TemplateRequestData>;
}
/**
 * Similar to ReservationRequestData but without contact info and with provider and state info.
 */
export interface TemplateRequestData {
    id: number;
    reference: string;
    destinations: Array<ModelRef>;
    comments: string;
    travel_start_date: Date;
    travel_days: number;
    reclaimed: boolean;
    amount_travelers: number;
    travel_type: string;
    budget: number;
    interests: Array<ModelRef>;
    traveler_type: string;
    provider: ModelRef;
    // Link to the provider's reservation, to be able to download the itinerary PDF
    reservation?: number;
    is_sent: boolean;
}
export interface ImportedTemplateData {
    id: number;
    provider_name: string;
    sales_order: SalesOrder;
    currency: string;
}
/**
 * Full info of the reservation across all of its tabs, loaded once when the reservation is opened.
 */
export interface ReservationDetailData {
    id: number;
    state: ReservationState;
    clients: ContactMinimalData[];
    title: string;
    destinations: Array<string>;
    travel_start_date: string;
    travel_type?: string;
    traveler_type?: string;
    amount_travelers: number;
    itinerary: DayBlockData[];
    itinerary_days: number;
    interests: Array<ModelRef>;
    budget: number;
    included: string;
    not_included: string;
    cancellation_policy: string;
    create_datetime?: Date;
    comments: string;
    attachments: Array<AttachmentData>;
    sales_order: SalesOrder;
    currency: string;
    template_requests?: Array<TemplateRequestContainer>;
    selected_version: number;
    versions: ModelRef[];
    extra_policies: ExtraPolicy[];
    assignee: EmployeeData;
    imported_templates: ImportedTemplateData[];
    imported_reservations: ImportedTemplateData[];
    cover_image?: MokenImage;
    countries: Array<ModelRef>;
    threads: Array<ModelRef>;
}
/**
 * Reduced info for the list view.
 * The detail view has a lot more info so it is fetched separately when a template is opened.
 */
export interface TemplateListData {
    id: number;
    title: string;
    itinerary_days: number;
    countries: Array<ModelRef>;
    travel_type: string;
    modified_datetime: string;
    public: boolean;
}
/**
 * Full info of the template across all of its tabs, loaded once when the reservation template is opened.
 */
export interface TemplateDetailData {
    id: number;
    title: string;
    destinations: Array<string>;
    itinerary_days: number;
    travel_type?: string;
    traveler_type?: string;
    interests: Array<ModelRef>;
    included: string;
    not_included: string;
    cancellation_policy: string;
    create_datetime?: Date;
    comments: string;
    public: boolean;
    sales_order: SalesOrder;
    currency: string;
    extra_policies: ExtraPolicy[];
    cover_image?: MokenImage;
    countries: Array<ModelRef>;
}
export interface EmailTemplate {
    id: number;
    name: string;
    message: string;
    modified_datetime: string;
}
/**
 * Notification type
 */
export type Notification = {
    recipients: Array<number>;
    sender: string;
    type: string;
};
/**
 * Notification data type
 */
export type NotificationData = {
    id: number;
    linked_model: NotificactionModelRef;
    title: string;
    message: string;
    seen: boolean;
    sender?: ContactMinimalData;
};

/**
 * client template data
 */
export interface ClientTemplate {
    id: number;
    provider_name: string;
    title: string;
    itinerary_days: number;
    countries: string[];
    travel_type: string;
}

/**
 * Dashboard screen travel data
 */
export interface DashboardTravelData {
    country: ModelRef;
    country_code: string;
    location: ModelRef;
    reservation: ReservationDetailData;
}

/**
 * History owner data
 */
export interface HistoryOwnerData {
    company: string;
    full_name: string;
    id: number;
    is_user: boolean;
    profile_image: MokenImage;
}

/**
 * History message data
 */
export interface HistoryMessage {
    create_datetime: string;
    email_sent: boolean;
    is_notification: boolean;
    message: string;
    owner: HistoryOwnerData;
}

export interface RegisterErrorDataObject {
    password: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    company_name: string;
    official_name: string;
    vat: string;
    company_country: number;
    company_size: string;
    company_type: string;
}

/**
 * History message data
 */
export interface RegisterErrorObject {
    config: object;
    data: RegisterErrorDataObject;
    header: object;
    request: object;
    status: number;
    statusText: string;
}

/**
 * HIstory messages and other stuff data
 */
export interface HistoryChannelData {
    id: string;
    messages: Array<HistoryMessage>;
    participants: Array<ContactMinimalData>;
}

export interface LoginInfo {
    success: boolean;
    error?: string;
}

export interface RegisterInfo {
    success: boolean;
    error?: RegisterErrorObject;
}
