import React from "react";
import Container from "../components/Container";
import TextInput from "../components/TextInput";
import { DiscountLine, ExtraLine, ImportedTemplateData, SalesOrder, ServiceLine } from "../types";
import CollapsableSection from "../components/CollapsableSection";
import Button from "../components/Button";
import IconButton from "../components/IconButton";
import { getLowestInvalidId } from "../utils";

interface PricingSectionProps {
    currency: string;
    salesOrder: SalesOrder;
    onModifySalesOrders: (newSalesOrders: SalesOrder) => void;
    onModifyCurrency: (s: string) => void;
    importedTemplates?: ImportedTemplateData[];
}

const PricingSection: React.FC<PricingSectionProps> = ({
    currency,
    salesOrder,
    onModifySalesOrders,
    onModifyCurrency,
    importedTemplates,
}) => {
    const handleServiceLinesOnChange = <K extends keyof ServiceLine, P extends ServiceLine[K]>(
        ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        lineField: K,
        lineIndex: number
    ) => {
        const newSo = salesOrder;
        const finalValue = ev.currentTarget.value ? ev.currentTarget.value : "0.00";
        newSo.services[lineIndex][lineField] = finalValue as P;
        onModifySalesOrders(newSo);
    };

    const handleExtraLinesOnChange = <K extends keyof ExtraLine, P extends ExtraLine[K]>(
        ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        lineField: K,
        lineIndex: number
    ) => {
        const newSo = salesOrder;
        const finalValue = ev.currentTarget.value ? ev.currentTarget.value : "0.00";
        newSo.extras[lineIndex][lineField] = finalValue as P;
        onModifySalesOrders(newSo);
    };

    const handleDiscountLinesOnChange = <K extends keyof DiscountLine, P extends DiscountLine[K]>(
        ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        lineField: K,
        lineIndex: number
    ) => {
        const newSo = salesOrder;
        const finalValue = ev.currentTarget.value ? ev.currentTarget.value : "0.00";
        newSo.discounts[lineIndex][lineField] = finalValue as P;
        onModifySalesOrders(newSo);
    };

    const handleSelectServiceLinePrice = (selectedPackage: string, serviceIdx: number) => {
        const newSo = salesOrder;
        newSo.services[serviceIdx].selected_package = selectedPackage;
        onModifySalesOrders(newSo);
    };

    const onAddNewServiceLine = () => {
        const newSo = salesOrder;
        // Unsaved lines are stored with IDs 0, -1, -2...
        newSo.services.push({
            id: getLowestInvalidId(newSo.services),
            name: "",
            selected_package: "std",
            price: "0",
            superior_price: "0",
            luxury_price: "0",
            markup: 0,
            sales_order: newSo.id,
        });
        onModifySalesOrders(newSo);
    };

    const onAddNewExtraLine = () => {
        const newSo = salesOrder;
        // Unsaved extras are stored with IDs 0, -1, -2...
        newSo.extras.push({
            id: getLowestInvalidId(newSo.extras),
            name: "",
            observations: "",
            price: "0",
            markup: 0,
            sales_order: newSo.id,
        });
        onModifySalesOrders(newSo);
    };

    const onAddNewDiscountLine = () => {
        const newSo = salesOrder;
        // Unsaved discounts are stored with IDs 0, -1, -2...
        newSo.discounts.push({
            id: getLowestInvalidId(newSo.discounts),
            name: "",
            observations: "",
            price: "0",
            sales_order: newSo.id,
        });
        onModifySalesOrders(newSo);
    };

    const onDeleteServiceLine = (serviceLineIdx: number) => {
        if (salesOrder.services.length <= 1) return;
        const newSo = salesOrder;
        newSo.services.splice(serviceLineIdx, 1);
        onModifySalesOrders(newSo);
    };

    const onDeleteExtraLine = (extraIdx: number) => {
        if (salesOrder.extras.length <= 1) return;
        const newSo = salesOrder;
        newSo.extras.splice(extraIdx, 1);
        onModifySalesOrders(newSo);
    };

    const onDeleteDiscountLine = (discountIdx: number) => {
        if (salesOrder.discounts.length <= 1) return;
        const newSo = salesOrder;
        newSo.discounts.splice(discountIdx, 1);
        onModifySalesOrders(newSo);
    };

    return (
        <Container>
            <div className="grid pt-8 grid-cols-12 gap-x-4 gap-y-2 mb-20">
                <h2 className="text-2xl font-bold font-sans col-span-full">Precio</h2>
                <div className="w-24 pt-2 col-span-full">
                    <TextInput
                        id="currency"
                        key="currency"
                        type="text"
                        label="Moneda"
                        maxLength={3}
                        defaultValue={currency}
                        onChange={(ev) => onModifyCurrency(ev.target.value)}
                    />
                </div>
                <div className="flex flex-col pb-10 space-y-10 col-span-full">
                    <div>
                        {salesOrder.services.map((service: ServiceLine, serviceIdx: number) => (
                            <div
                                className="grid grid-cols-12 gap-x-4 gap-y-6 mb-4"
                                key={`so_${salesOrder.id}_service_${service.id}_line`}
                            >
                                <div
                                    className={
                                        salesOrder.services.length > 1
                                            ? "col-span-12 md:col-span-5"
                                            : "col-span-12 md:col-span-6"
                                    }
                                >
                                    <TextInput
                                        id={`so_${salesOrder.id}_service_${service.id}_ref`}
                                        key={`so_${salesOrder.id}_service_${service.id}_ref`}
                                        type="text"
                                        label="Título"
                                        defaultValue={service.name}
                                        onChange={(ev) => handleServiceLinesOnChange(ev, "name", serviceIdx)}
                                    />
                                </div>
                                <div className={ salesOrder.services.length > 1 ? "col-span-3 md:col-span-2": "col-span-4 md:col-span-2" }>
                                    <TextInput
                                        id={`so_${salesOrder.id}_service_${service.id}_std`}
                                        key={`so_${salesOrder.id}_service_${service.id}_std`}
                                        type="text"
                                        label="Standard"
                                        defaultValue={parseFloat(service.price).toFixed(2)}
                                        selected={salesOrder.services[serviceIdx].selected_package === "std"}
                                        onChange={(ev) => handleServiceLinesOnChange(ev, "price", serviceIdx)}
                                        onClick={() => {
                                            handleSelectServiceLinePrice("std", serviceIdx);
                                        }}
                                    />
                                </div>
                                <div className={ salesOrder.services.length > 1 ? "col-span-3 md:col-span-2": "col-span-4 md:col-span-2" }>
                                    <TextInput
                                        id={`so_${salesOrder.id}_service_${service.id}_sup`}
                                        key={`so_${salesOrder.id}_service_${service.id}_sup`}
                                        type="text"
                                        label="Superior"
                                        defaultValue={parseFloat(service.superior_price).toFixed(2)}
                                        selected={salesOrder.services[serviceIdx].selected_package === "sup"}
                                        onChange={(ev) => handleServiceLinesOnChange(ev, "superior_price", serviceIdx)}
                                        onClick={() => {
                                            handleSelectServiceLinePrice("sup", serviceIdx);
                                        }}
                                    />
                                </div>
                                <div className={ salesOrder.services.length > 1 ? "col-span-3 md:col-span-2": "col-span-4 md:col-span-2" }>
                                    <TextInput
                                        id={`so_${salesOrder.id}_service_${service.id}_lux`}
                                        key={`so_${salesOrder.id}_service_${service.id}_lux`}
                                        type="text"
                                        label="Luxury"
                                        defaultValue={parseFloat(service.luxury_price).toFixed(2) || "0.00"}
                                        selected={salesOrder.services[serviceIdx].selected_package === "lux"}
                                        onChange={(ev) => handleServiceLinesOnChange(ev, "luxury_price", serviceIdx)}
                                        onClick={() => {
                                            handleSelectServiceLinePrice("lux", serviceIdx);
                                        }}
                                    />
                                </div>
                                {salesOrder.services.length > 1 && (
                                    <div className="col-span-3 md:col-span-1 border border-red rounded-md flex justify-center items-center shadow">
                                        <IconButton
                                            icon="delete"
                                            iconSize="27px"
                                            color="red"
                                            extraClass="text-[20px]"
                                            onClick={() => onDeleteServiceLine(serviceIdx)}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="max-w-full flex justify-end">
                            <Button
                                label="Añadir"
                                icon="add"
                                onClick={() => onAddNewServiceLine()}
                                type="btn_yellow"
                                title="Añadir precio"
                            />
                        </div>
                    </div>
                    <CollapsableSection title="1.2 Suplementos" arrowSize="sm">
                        <div className="">
                            {salesOrder.extras.map((extra: ExtraLine, extraIdx: number) => (
                                <div
                                    className="grid grid-cols-12 gap-x-4 gap-y-6 mb-4 pt-2"
                                    key={`so_${salesOrder.id}_extra_${extra.id}_line`}
                                >
                                    <div className="col-span-12 md:col-span-4">
                                        <TextInput
                                            id={`so_${salesOrder.id}_extra_${extra.id}_title`}
                                            key={`so_${salesOrder.id}_extra_${extra.id}_title`}
                                            type="text"
                                            label="Título"
                                            defaultValue={extra.name}
                                            onChange={(ev) => handleExtraLinesOnChange(ev, "name", extraIdx)}
                                        />
                                    </div>
                                    <div
                                        className={
                                            salesOrder.extras.length > 1
                                                ? "col-span-12 md:col-span-5"
                                                : "col-span-12 md:col-span-6"
                                        }
                                    >
                                        <TextInput
                                            id={`so_${salesOrder.id}_extra_${extra.id}_observations`}
                                            key={`so_${salesOrder.id}_extra_${extra.id}_observations`}
                                            type="text"
                                            label="Observaciones"
                                            defaultValue={extra.observations}
                                            onChange={(ev) => handleExtraLinesOnChange(ev, "observations", extraIdx)}
                                        />
                                    </div>
                                    <div className="col-span-3 md:col-span-2">
                                        <TextInput
                                            id={`so_${salesOrder.id}_extra_${extra.id}_price`}
                                            key={`so_${salesOrder.id}_extra_${extra.id}_price`}
                                            type="text"
                                            label="Importe"
                                            defaultValue={parseFloat(extra.price).toFixed(2)}
                                            onChange={(ev) => handleExtraLinesOnChange(ev, "price", extraIdx)}
                                        />
                                    </div>
                                    {salesOrder.extras.length > 1 && (
                                        <div className="col-span-3 md:col-span-1 border border-red rounded-md flex justify-center items-center shadow">
                                            <IconButton
                                                icon="delete"
                                                iconSize="27px"
                                                color="red"
                                                onClick={() => onDeleteExtraLine(extraIdx)}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="w-full flex justify-end">
                                <Button
                                    label="Añadir"
                                    icon="add"
                                    onClick={() => onAddNewExtraLine()}
                                    type="btn_yellow"
                                    title="Añadir suplemento"
                                />
                            </div>
                        </div>
                    </CollapsableSection>
                    <CollapsableSection title="1.3 Descuentos" arrowSize="sm">
                        <div>
                            {salesOrder.discounts.map((discount: DiscountLine, discountIdx: number) => (
                                <div
                                    className="grid grid-cols-12 gap-x-4 gap-y-6 mb-4 pt-2"
                                    key={`so_${salesOrder.id}_discount_${discount.id}_line`}
                                >
                                    <div className="col-span-12 md:col-span-4">
                                        <TextInput
                                            id={`so_${salesOrder.id}_discount_${discount.id}_title`}
                                            key={`so_${salesOrder.id}_discount_${discount.id}_title`}
                                            type="text"
                                            label="Título"
                                            defaultValue={discount.name}
                                            onChange={(ev) => handleDiscountLinesOnChange(ev, "name", discountIdx)}
                                        />
                                    </div>
                                    <div
                                        className={
                                            salesOrder.discounts.length > 1
                                                ? "col-span-12 md:col-span-5"
                                                : "col-span-12 md:col-span-6"
                                        }
                                    >
                                        <TextInput
                                            id={`so_${salesOrder.id}_discount_${discount.id}_observations`}
                                            key={`so_${salesOrder.id}_discount_${discount.id}_observations`}
                                            type="text"
                                            label="Observaciones"
                                            defaultValue={discount.observations}
                                            onChange={(ev) =>
                                                handleDiscountLinesOnChange(ev, "observations", discountIdx)
                                            }
                                        />
                                    </div>
                                    <div className="col-span-3 md:col-span-2">
                                        <TextInput
                                            id={`so_${salesOrder.id}_discount_${discount.id}_price`}
                                            key={`so_${salesOrder.id}_discount_${discount.id}_price`}
                                            type="text"
                                            label="Descuento"
                                            defaultValue={parseFloat(discount.price).toFixed(2)}
                                            onChange={(ev) => handleDiscountLinesOnChange(ev, "price", discountIdx)}
                                        />
                                    </div>
                                    {salesOrder.discounts.length > 1 /* && discountIdx!==0 */ && (
                                        <div className="col-span-3 md:col-span-1 border border-red rounded-md flex justify-center items-center shadow">
                                            <IconButton
                                                icon="delete"
                                                iconSize="27px"
                                                color="red"
                                                onClick={() => onDeleteDiscountLine(discountIdx)}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="w-full flex justify-end">
                                <Button
                                    label="Añadir"
                                    icon="add"
                                    onClick={() => onAddNewDiscountLine()}
                                    type="btn_yellow"
                                    title="Añadir descuento"
                                />
                            </div>
                        </div>
                    </CollapsableSection>
                </div>
                {importedTemplates && importedTemplates.length > 0 && (
                    <>
                        <div className="col-span-full">
                            <h2 className="text-xl font-bold font-sans col-span-full">Tarifarios importados</h2>
                            <p className="text-grey-light-2 col-span-full mt-4">
                                El precio de los tarifarios que se han importado en el itinerario.
                            </p>
                        </div>
                        <div className="col-span-full">
                            {importedTemplates.map((it) => (
                                <CollapsableSection key={it.id} title={it.provider_name} closedByDefault arrowSize="sm">
                                    <div className="flex flex-col p-10 space-y-10">
                                        <div className="flex flex-col space-y-5">
                                            <div className="flex flex-row items-center space-x-5">
                                                <p className="basis-5/12">Título</p>
                                                <p className="basis-2/12">Standard</p>
                                                <p className="basis-2/12">Superior</p>
                                                <p className="basis-2/12">Luxury</p>
                                                <p className="basis-1/12">Moneda</p>
                                            </div>
                                            {it.sales_order.services.map((service: ServiceLine) => (
                                                <div
                                                    className="flex flex-row items-center space-x-5 text-grey"
                                                    key={`so_${it.sales_order.id}_service_${service.id}_line`}
                                                >
                                                    <div className="basis-5/12">{service.name}</div>
                                                    <div className="basis-2/12">
                                                        {parseFloat(service.price).toFixed(2)}
                                                    </div>
                                                    <div className="basis-2/12">
                                                        {parseFloat(service.superior_price).toFixed(2)}
                                                    </div>
                                                    <div className="basis-2/12">
                                                        {parseFloat(service.luxury_price).toFixed(2)}
                                                    </div>
                                                    <div className="basis-1/12">{it.currency}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {it.sales_order.extras.filter((extra) => parseFloat(extra.price) > 0).length >
                                            0 && (
                                            <CollapsableSection title="Suplementos" arrowSize="sm">
                                                <div className="flex flex-col space-y-5 pl-10">
                                                    <div className="flex flex-row items-center space-x-5">
                                                        <p className="basis-6/12">Título</p>
                                                        <p className="basis-3/12">Observaciones</p>
                                                        <p className="basis-2/12">Importe</p>
                                                        <p className="basis-1/12">Moneda</p>
                                                    </div>
                                                    {it.sales_order.extras.map(
                                                        (extra: ExtraLine) =>
                                                            parseFloat(extra.price) > 0 && (
                                                                <div
                                                                    className="flex flex-row items-center space-x-5 text-grey"
                                                                    key={`so_${it.sales_order.id}_extra_${extra.id}_line`}
                                                                >
                                                                    <div className="basis-6/12">{extra.name}</div>
                                                                    <div className="basis-3/12">
                                                                        {extra.observations}
                                                                    </div>
                                                                    <div className="basis-2/12">
                                                                        {parseFloat(extra.price).toFixed(2)}
                                                                    </div>
                                                                    <div className="basis-1/12">{it.currency}</div>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </CollapsableSection>
                                        )}
                                        {it.sales_order.discounts.filter((discount) => parseFloat(discount.price) > 0)
                                            .length > 0 && (
                                            <CollapsableSection title="Descuentos" arrowSize="sm">
                                                <div className="flex flex-col space-y-5 pl-10">
                                                    <div className="flex flex-row items-center space-x-5">
                                                        <p className="basis-6/12">Título</p>
                                                        <p className="basis-3/12">Observaciones</p>
                                                        <p className="basis-2/12">Descuento</p>
                                                        <p className="basis-1/12">Moneda</p>
                                                    </div>
                                                    {it.sales_order.discounts.map(
                                                        (discount: DiscountLine) =>
                                                            parseFloat(discount.price) > 0 && (
                                                                <div
                                                                    className="flex flex-row items-center space-x-10 text-grey"
                                                                    key={`so_${it.sales_order.id}_discount_${discount.id}_line`}
                                                                >
                                                                    <div className="basis-6/12">{discount.name}</div>
                                                                    <div className="basis-3/12">
                                                                        {discount.observations}
                                                                    </div>
                                                                    <div className="basis-2/12">
                                                                        {parseFloat(discount.price).toFixed(2)}
                                                                    </div>
                                                                    <div className="basis-1/12">{it.currency}</div>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </CollapsableSection>
                                        )}
                                    </div>
                                </CollapsableSection>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
};

export default PricingSection;
