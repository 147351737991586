import classNames from "classnames";
import React, { MouseEvent } from "react";

export interface DropdownItemProps {
    /**
     * Text shown within the button.
     */
    label: string;
    /**
     * Click handler for when the item is clicked.
     */
    onClick: () => void;
    /**
     * MouseDown handler for when the item is clicked.
     */
    onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
    /**
     * Color for the label.
     */
    labelColor?: "black" | "red";

}

/**
 * Clickable element which comprises a row within a dropdown box.
 */
const DropdownItem: React.FC<DropdownItemProps> = ({ label, onClick, onMouseDown, labelColor = "black" }) => (
    <button
        className="text-sm py-5 sm:py-2 px-4 font-normal flex items-start justify-start w-full bg-transparent hover:bg-platinum text-left"
        onClick={onClick}
        onMouseDown={onMouseDown}
        type="button"
    >
        <span className={classNames(labelColor === "red" && "text-red")}>{label}</span>
    </button>
);

export default DropdownItem;
