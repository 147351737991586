import React, { useState } from "react";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import ImageSelectModal from "./modals/ImageSelectModal";
import { MokenImage } from "../types";

export interface ImageSelectorProps {
    /**
     * Label describing the image selector, shown on the top left.
     */
    label?: string;
    /**
     * Currently selected image, shown as thumbnail
     */
    image?: MokenImage;

    onAccept: (image: MokenImage) => void;

    /**
     * The available Moken image to show in the modal.
     */
    mokenImage: MokenImage;

    /**
     * For imported days, the image that the provider set for the day.
     */
    providerImage?: MokenImage;

    /**
     * Optional description box for Unsplash images.
     */
    description?: string;

    /**
     * Optional link for the description to the Unsplash user.
     */
    linkTo?: string;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
    label,
    image,
    onAccept,
    mokenImage,
    providerImage,
    description,
    linkTo,
}) => {
    const [validImage, setValidImage] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    let descriptionBlock = <p className="text-white p-2">{description}</p>;
    if (linkTo !== undefined) {
        descriptionBlock = (
            <a href={linkTo} target="_blank" rel="noreferrer">
                {descriptionBlock}
            </a>
        );
    }

    const clickHandler = () => setIsModalVisible(true);
    return (
        <div className="relative flex flex-col w-full h-full">
            {label && <p className="font-sans font-semibold text-sm text-grey-light-1 absolute top-[-24px]">{label}</p>}
            <div
                className="relative w-full h-full flex items-center justify-center rounded-md border border-grey-light-2  shadow"
                onClick={clickHandler}
                onKeyPress={clickHandler}
                role="button"
                tabIndex={0}
            >
                {image?.image && validImage ? (
                    <div className="w-full h-full flex relative group">
                        <img
                            src={image.image}
                            alt="Location"
                            className="w-full h-full flex justify-center items-center rounded-md object-cover text-center pointer-events-none object-cover h-full absolute"
                            onError={() => setValidImage(false)}
                        />
                        {description && (
                            <div
                                className={classNames(
                                    "items-center justify-center bg-black bg-opacity-50 absolute",
                                    "bottom-0 h-14 w-full rounded-b-2xl",
                                    "hidden group-hover:flex"
                                )}
                            >
                                {descriptionBlock}
                            </div>
                        )}
                    </div>
                ) : (
                    <Icon className="text-5xl text-grey-light-2" sx={{ fontSize: "77px" }}>
                        block
                    </Icon>
                )}
            </div>

            {/* Modals */}
            <ImageSelectModal
                visible={isModalVisible}
                visibleHandler={setIsModalVisible}
                onAccept={onAccept}
                mokenImage={validImage ? mokenImage : undefined}
                providerImage={providerImage}
                selectedImage={image}
            />
        </div>
    );
};

export default ImageSelector;
